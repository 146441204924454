import React, { FC, useEffect, useState } from 'react';
import styles from './job-list-item.module.scss';
import { Link } from 'react-router-dom';
import { IJobListItem } from '../../details/logic/models';
import { ITerm } from '../../../candidate/logic/models';
import JobHead from '../job-head/job-head';
import JobTags from '../../../../components/job-tags/job-tags';

export interface JobListItemProps {
  item: IJobListItem,
  index: number
}

const JobListItem: FC<JobListItemProps> = (props) => {
  const { index, item } = props; 

  let matchClassName = '';
  const matchValue = item.match;

  // Check the value and assign the appropriate class
  if (matchValue >= 5 && matchValue <= 30) {
    matchClassName = 'red';
  } else if (matchValue > 30 && matchValue <= 60) {
    matchClassName = 'orange';
  } else if (matchValue > 60 && matchValue <= 100) {
    matchClassName = 'green';
  }

  let [headClassName, setHeadClassName] = useState('style-1');
  useEffect(() => {
    let className = 'style-0';
    if (index) {
      let classNumber = index % 5;
      className = 'style-' + classNumber; 
    }
    setHeadClassName(className);
  }, [index])
  return (
      <div
        className="job-block-two col-lg-12 col-md-12 col-sm-12"
        key={item.id}
      >
          <Link target="_blank" to={`/job/${item.id}`}>
            <div className="inner-box">
              { matchValue ? <div className={"job-your-match-container " + matchClassName}> 
              <i className="fa fa-signal"></i>
              Your match: {matchValue}%</div> :'' }
              <JobHead details={item} isLink={false} classNameLetter={headClassName} />
              {/* End .content */}

              <JobTags details={item} />
              {/* End .job-other-info */}

              <span className="fa fa-angle-right ms-1 flex-align-self-center" ></span>
            </div>
            {/* End inner-box */}
          </Link>
      </div>

  );
}

export default JobListItem;
