import React, { FC, useEffect, useState } from 'react';
import styles from './resume-form.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../app/store';
import { getProfileDetails, getTaxonomies, selectCandidateProfile, uploadFile } from '../logic/candidateSlice';
import { ICandidateEducation, ICandidateExperience, IIndustry, ICandidateLanguage, ICandidateSkill, FileUploadModes } from '../logic/models';
import EducationDetails from '../components/education-details/education-details';
import ExperienceDetails from '../components/experience-details/experience-details';
import LanguageDetails from '../components/language-details/language-details';
import { Button } from '@mui/material';
import SkillDetails from '../components/skill-details/skill-details';
import SkillForm from '../components/skill-form/skill-form.lazy';
import LanguageForm from '../components/language-form/language-form';
import EducationForm from '../components/education-form/education-form';
import ExperienceForm from '../components/experience-form/experience-form';
import IndustryDetails from '../components/industry-details/industry-details.lazy';
import IndustryForm from '../components/industry-form/industry-form.lazy';
import FileUpload from '../components/file-upload/file-upload';
import FileDetails from '../components/file-details/file-details';
import UserDescriptionForm from '../components/user-description-form/user-description-form';
import SkillsTableForm from '../components/skills-table-form/skills-table-form';


interface ResumeFormProps {}

const ResumeForm: FC<ResumeFormProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, userProfile} = useSelector(selectCandidateProfile);

  const [ isFileUploadVisible, setIsFileUploadVisible ] = useState(false)
  const [fileUploadMode, setFileUploadMode] = useState<FileUploadModes>(FileUploadModes.FILE);

  const initialEducationFormValues: ICandidateEducation | { } = { }
  const [ isEducationFormVisible, setIsEducationFormVisible ] = useState(false)
  const [ educationFormValues, setEducationFormValues ] = useState(initialEducationFormValues);

  const initialExperienceFormValues: ICandidateExperience | { } = { }
  const [ isExperienceFormVisible, setIsExperienceFormVisible ] = useState(false)
  const [ experienceFormValues, setExperienceFormValues ] = useState(initialExperienceFormValues);


  const initialLanguageFormValues: ICandidateLanguage = { }
  const [ isLanguageFormVisible, setIsLanguageFormVisible ] = useState(false)
  const [ languageFormValues, setLanguageFormValues ] = useState(initialLanguageFormValues);

  // load initial profile data
  useEffect(() => {
    dispatch(getProfileDetails())
    dispatch(getTaxonomies())
  }, [])

  const handleLanguageFormValues = (details: ICandidateLanguage) => {
    setLanguageFormValues(details);
    setIsLanguageFormVisible(true);
  }

  const handleExperienceFormValues = (details: ICandidateExperience) => {
    setExperienceFormValues(details);
    setIsExperienceFormVisible(true);
  }

  const handleEducationFormValues = (details: ICandidateEducation) => {
    setEducationFormValues(details);
    setIsEducationFormVisible(true);
  }
 
  return (
    <div className={styles.ResumeForm}>
      <section>
        <UserDescriptionForm></UserDescriptionForm>
        <EducationForm
          isEducationFormVisible={isEducationFormVisible}
          setIsEducationFormVisible={setIsEducationFormVisible}
          setEducationFormValues={setEducationFormValues}
          editEducation={educationFormValues}
        ></EducationForm>
        <div className="resume-outer">
          <div className="upper-title">
            <h4>Education</h4>
            <button className="add-info-btn" onClick={() => {
              setEducationFormValues(initialEducationFormValues);
              setIsEducationFormVisible(true);
            }}>
              <span className="icon flaticon-plus"></span> Add Education
            </button>
          </div>
          {userProfile?.candidate?.education.map((educationDetails) => (
              <EducationDetails
                key={educationDetails.id}
                details={educationDetails}
                setEducationFormValues={handleEducationFormValues}
              ></EducationDetails>
          ))}
        </div>    
      </section>

      <section>
        <ExperienceForm
          isExperienceFormVisible={isExperienceFormVisible}
          setIsExperienceFormVisible={setIsExperienceFormVisible}
          setExperienceFormValues={setExperienceFormValues}
          editExperience={experienceFormValues}
        ></ExperienceForm>
        <div className="resume-outer">
          <div className="upper-title">
            <h4>Experience</h4>
            <button className="add-info-btn"  onClick={() => {
              setExperienceFormValues(initialExperienceFormValues);
              setIsExperienceFormVisible(true);
            }}>
              <span className="icon flaticon-plus"></span> Add Experience
            </button>
          </div>
          {userProfile?.candidate?.experience.map((details) => (
              <ExperienceDetails
                key={details.id}
                details={details}
                setExperienceFormValues={handleExperienceFormValues}
              ></ExperienceDetails>
          ))}
        </div>
      </section>

      <section>
        <LanguageForm
          isLanguageFormVisible={isLanguageFormVisible}
          setIsLanguageFormVisible={setIsLanguageFormVisible}
          setLanguageFormValues={setLanguageFormValues}
          editLanguage={languageFormValues}
        ></LanguageForm>
        <div className="resume-outer">
          <div className="upper-title">
            <h4>Languages</h4>
            <button className="add-info-btn" onClick={() => {
              setLanguageFormValues(initialLanguageFormValues);
              setIsLanguageFormVisible(true);
            }}>
              <span className="icon flaticon-plus"></span> Add Language
            </button>
          </div>
          <div className="table-outer">
            <div className="table-outer">
              <table className="default-table manage-job-table">
                <thead>
                  <tr>
                    <th>Job Title</th>
                    <th>Read</th>
                    <th>Write</th>
                    <th>Speak</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {userProfile?.candidate?.language.map((details) => (
                    <tr key={details.id}>
                      <LanguageDetails key={details.id} details={details} setLanguageFormValues={handleLanguageFormValues}></LanguageDetails>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>


      <section>

        <div className="resume-outer">
          <div className="upper-title">
            <h4>Documents</h4>
            <button className="add-info-btn" onClick={() => {
              setFileUploadMode(FileUploadModes.FILE);
              setIsFileUploadVisible(true)
            }}>
              <span className="icon flaticon-plus"></span> Add Document
            </button>
          </div>
          <div className="table-outer">
            <div className="table-outer">
              <table className="default-table manage-job-table">
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>File Type</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                {userProfile?.candidate?.files.map((item) => (
                  <tr key={item.id}>
                    <FileDetails key={item.id} details={item}></FileDetails>
                   </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <fieldset className='mt-5'>
        <FileUpload
          fileUploadMode={fileUploadMode}
          isFileUploadVisible={isFileUploadVisible}
          setIsFileUploadVisible={setIsFileUploadVisible}
        ></FileUpload>
      </fieldset>
    </div>
  );
}

export default ResumeForm;
