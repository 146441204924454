import { Autocomplete, Chip, TextField } from '@mui/material';

import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import api from '../../app/api';
import { ISkill } from '../../features/candidate/logic/models';

export interface SkillAutocompleteProps {
  formik?: any,
  value?: ISkill | ISkill[] | null,
  onChange?: Function
  multiple?: boolean
  disableCloseOnSelect?: boolean
}

const SkillAutocomplete = (props: SkillAutocompleteProps) => {
  const [value, setValue] = useState<ISkill| ISkill[] | null>(null);
  const [options, setOptions] = useState([]);

  // Fetch autocomplete suggestions
  const fetchSuggestions = async (query: any) => {
    if (query.length > 2) {
      try {
        const response = await api.get('/taxonomy/skill', {
          params: { name: query },
        });
        setOptions(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  };
  // Debounce the fetchSuggestions function to limit the frequency of API requests
  const debouncedFetchSuggestions = debounce(fetchSuggestions, 300);

  useEffect(() => {
    return () => {
      // Clean up the debounced function on component unmount
      debouncedFetchSuggestions.cancel();
    };
  }, []);

  // Handle option selection
  const handleSelect = (event: any, newValue: ISkill | ISkill[]| null) => {
    setValue(newValue);
    if (props.onChange) {
      props.onChange(newValue)
    }
  };

  // Handle text input change
  const handleInputChange = (event: any, newQuery: any) => {
    debouncedFetchSuggestions(newQuery);
  };

  return (
    <Autocomplete
      multiple={props.multiple}
      disableCloseOnSelect= {props.disableCloseOnSelect}
      options={options}
      getOptionLabel = {(option: ISkill) => {
        return option.name
      }}

      isOptionEqualToValue ={(option, value) => {
        return option.id === value.id
      }}

      value={props.value ? props.value : props.multiple ? [] : null}
      onChange={(e, value) => {
          handleSelect(e, value);
        }
      }
      onInputChange={handleInputChange}
      renderTags={(values: readonly any[], getTagProps) =>
        values.map((option: ISkill, index: number) => (
          <Chip
            label={option.name}
            {...getTagProps({ index })}

            deleteIcon={<i className="fa fa-times"/>}
          ></Chip>
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          helperText="Start typing to load autocomplete list"
          InputProps={{
            ...params.InputProps,
            // disableUnderline: true,
          }}
        />
      )}
    />
  );
};

export default SkillAutocomplete;
