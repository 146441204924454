import React, { FC } from 'react';
import styles from './reset-password-page.module.scss';
import ResetPasswordForm from '../../features/auth/reset-password-form/reset-password-form';
import Semiscreen from '../../components/layout/semiscreen/semiscreen';

interface ResetPasswordPageProps {}

const ResetPasswordPage: FC<ResetPasswordPageProps> = () => {
  return (
    <Semiscreen>
      {/* <!-- Login Form --> */}
      <div className="login-form default-form">
        <h1>Reset Password</h1>
        <ResetPasswordForm/>
      </div>
      {/* <!--End Login Form --> */}
    </Semiscreen>
  );
}
(
  <div className={styles.ResetPasswordPage}>
  </div>
);

export default ResetPasswordPage;
