import React, { FC } from 'react';
import styles from './language-details.module.scss';
import { ICandidateLanguage } from '../../logic/models';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { deleteLanguage } from '../../logic/candidateSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../app/store';

interface LanguageDetailsProps {
  details: ICandidateLanguage,
  setLanguageFormValues?: Function
}

const LanguageDetails: FC<LanguageDetailsProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    if (props.setLanguageFormValues) {
      props.setLanguageFormValues(props.details);
    }
  }

  const handleDelete = () => {
    dispatch(deleteLanguage({id:props.details.id}))
  }
  return (
    <>
      <td>{props.details.language?.name}</td>
      <td>{props.details.read?.name}</td>
      <td>{props.details.write?.name}</td>
      <td>{props.details.speak?.name}</td>

      <td>
        <div className="option-box">
          <ul className="option-list">
            <li>
              <button data-text="Edit language" onClick={handleEdit}>
                <span className="la la-pencil"></span>
              </button>
            </li>
            <li>
              <button data-text="Delete Language" onClick={handleClickOpen}>
                <span className="la la-trash"></span>
              </button>
            </li>
          </ul>
        </div>
      </td>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm language removal?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="container">
              <div className="row">
                  <div className="form-group col-lg-12 col-md-12 mt-2 mb-4 d-flex justify-content-end">
                    <button onClick={handleClose} className="theme-btn btn-style-eight narrow ">Cancel</button>
                    <button  onClick={handleDelete}  type="submit" className="theme-btn btn-style-one narrow ms-2">Delete</button>
                  </div>
                </div>
              </div>
          </DialogActions>
      </Dialog>
    </>
     
)};

export default LanguageDetails;
