import React, { FC, useEffect, useState } from 'react';
import styles from './job-tags.module.scss';
import { IJobDetails, IJobListItem } from '../../features/job/details/logic/models';
import { ITerm } from '../../features/candidate/logic/models';

export interface JobTagsProps {
  details: IJobDetails | IJobListItem
}

export interface  IJobType {
  type: string,
  styleClass: string
}

enum StyleClass {
  TIME = 'time',
  GREEN = 'green',
  PRIVACY = 'privacy',
  REQUIRED = 'required',
  PURPLE = 'purple',
  ORANGE = 'orange',
  RED = 'red',
}

const JobTags: FC<JobTagsProps> = (props) => {
  const { details } = props; 
  let [jobTypes, setJobTypes] = useState<IJobType[]>([]);

  useEffect(() => {
    let jobType:IJobType[] = [];
    jobType.push(...convertArrayToJobType(details.position_levels, {
      26: StyleClass.GREEN,
      27: StyleClass.ORANGE,
      28: StyleClass.ORANGE,
      29: StyleClass.RED,
      30: StyleClass.RED,
    }));
    jobType.push(...convertArrayToJobType(details.job_types, StyleClass.TIME));
    jobType.push(...convertArrayToJobType(details.remote_types, StyleClass.PURPLE));
    setJobTypes(jobType);
  }, [details])
  const convertArrayToJobType = (items: ITerm[], style: StyleClass | any) => {
    if(!items) {
      return [];
    }
    const values: IJobType[] = []
    for(let i = 0; i < items.length; i++) {
      let styleClass: any;
      if (typeof style === 'object') {
        styleClass= style[items[i].id];
      }
      else {
        styleClass = style;
      }
      values.push({
        type: items[i].name,
        styleClass: styleClass
      })
    }
    return values;
  }

  return (
    <ul className="job-other-info">
      {jobTypes.map((val: any, i: number) => (
        <li key={i} className={`${val.styleClass}`}>
          {val.type}
        </li>
      ))}
    </ul>
    
  );
}

export default JobTags;
