import React, { lazy, Suspense } from 'react';

const LazyTestimonialCarousel = lazy(() => import('./testimonial-carousel'));

const TestimonialCarousel = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyTestimonialCarousel {...props} />
  </Suspense>
);

export default TestimonialCarousel;
