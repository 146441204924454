import React, { useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";

import logo from './logo.svg';
import './App.css';
import Main from './components/layout/main/main';
import Navigation from './components/layout/navigation/navigation';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple, red } from '@mui/material/colors';


const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#1967D2',
      dark: '#1967D2',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF', // Set your desired background color
          borderRadius: '5px',       // Set your desired border radius
          color: '#333',             // Set the text color
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: '#1967D2', 
          textTransform: 'none'
        },
        icon: {
          fill: '#1967D2', // Set dropdown icon color to #FF00FF
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF', // Set your desired background color
          borderRadius: '5px',       // Set your desired border radius
          color: 'blue',             // Set the text color
        }
      }
    }
  },
});

function App() {
    // aos animation activation
    useEffect(() => {
      Aos.init({
        duration: 1400,
        once: true,
      });
    }, []);
  
  return (
    <ThemeProvider theme={theme}>
      <div className="App page-wrapper"> 
        <Main></Main>
      </div>
    </ThemeProvider>
  );
}

export default App;
function createRoutesFromElements(arg0: JSX.Element): any {
  throw new Error('Function not implemented.');
}

function createBrowserRouter(arg0: any) {
  throw new Error('Function not implemented.');
}

function sleep(arg0: number) {
  throw new Error('Function not implemented.');
}

