import { Autocomplete, TextField } from '@mui/material';

import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { ICity } from '../../features/candidate/logic/models';
import api from '../../app/api';

export interface CityAutocompleteProps {
  formik?: any,
  city?: ICity | null,
  setCity?: Function,
  hideInfo?: boolean,
  isOutlined?: boolean
}

const CityAutocomplete = (props: CityAutocompleteProps) => {
  const [value, setValue] = useState<ICity | null>(null);
  const [options, setOptions] = useState([]);
  const hideInfo = props.hideInfo != null ? props.hideInfo : false;

  let variantProps = props.isOutlined ? {
    variant: 'outlined' as 'outlined'
  } : {
    variant: 'standard' as 'standard'
  }
  
  // Fetch autocomplete suggestions
  const fetchSuggestions = async (query: any) => {
    try {
      const response = await api.get('/taxonomy/city', {
        params: { name: query },
      });
      setOptions(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  // Debounce the fetchSuggestions function to limit the frequency of API requests
  const debouncedFetchSuggestions = debounce(fetchSuggestions, 300);

  useEffect(() => {
    return () => {
      // Clean up the debounced function on component unmount
      debouncedFetchSuggestions.cancel();
    };
  }, []);

  // Handle option selection
  const handleSelect = (event: any, newValue: ICity | null) => {
    setValue(newValue);
    if (props.setCity) {
      props.setCity(newValue)
    }
  };

  // Handle text input change
  const handleInputChange = (event: any, newQuery: any) => {
    debouncedFetchSuggestions(newQuery);
  };

  return (
    <Autocomplete
      options={options}
      sx={{
        flex: 1
      }}
      getOptionLabel = {(option: ICity) => {
        return option.name
      }}

      isOptionEqualToValue ={(option, value) => {
        return option.id === value.id
      }}

      value={props.city}
      onChange={(e, value) => {
          handleSelect(e, value);
        }
      }
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="City"

          variant='standard'
          helperText= {hideInfo ? '' : "Start typing to load autocomplete list"}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};

export default CityAutocomplete;
