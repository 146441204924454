import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { createAsExpression } from "typescript"
import api from "../../../app/api"
import { error } from "console"
import { ErrorMessage } from "formik"
import { RootState } from "../../../app/store"

export interface IResetPasswordState {
    isLoading: boolean,
    showSuccess: boolean,
    errorMessageGeneral: string | null,
    errorMessages: string[] | null
}
export interface IAuthState {
    sendResetPasswordLink: IResetPasswordState,
    resetPassword: IResetPasswordState
}

const initialState: IAuthState = {
    sendResetPasswordLink: {
        isLoading: false,
        showSuccess: false,
        errorMessageGeneral: null,
        errorMessages: null
    },
    resetPassword: {
        isLoading: false,
        showSuccess: false,
        errorMessageGeneral: null,
        errorMessages: null
    }
}

export const selectSendResetPassworLink = (state: RootState) => state.auth.sendResetPasswordLink;
export const selectResetPassword = (state: RootState) => state.auth.resetPassword;

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetResetPassword: (state, action) => {
            state.sendResetPasswordLink = initialState.sendResetPasswordLink;
            state.resetPassword = initialState.resetPassword;
        }
    },
    extraReducers(builder) {
        builder.addCase(postSendResetPasswordLink.pending, (state, action) => {
            state.sendResetPasswordLink.isLoading = true;
        })
        .addCase(postSendResetPasswordLink.rejected, (state, action: any) => {
            state.sendResetPasswordLink.isLoading = false;
            state.sendResetPasswordLink.errorMessageGeneral = action.payload?.detail;
        })
        .addCase(postSendResetPasswordLink.fulfilled, (state, action) => {
            state.sendResetPasswordLink.isLoading = false;
            state.sendResetPasswordLink.showSuccess = true;
        })
        .addCase(postResetPassword.pending, (state, action) => {
            state.resetPassword.isLoading = true;
        })
        .addCase(postResetPassword.rejected, (state, action) => {
            state.resetPassword.isLoading = false;
            state.resetPassword.errorMessages = <Array<string>>action.payload;
        })
        .addCase(postResetPassword.fulfilled, (state, action) => {
            state.resetPassword.isLoading = false;
            state.resetPassword.showSuccess = true;
        })
    }
})

export const postSendResetPasswordLink = createAsyncThunk(
    'auth/postSendResetPasswordLink',
    async (payload: any, {rejectWithValue}) => {
        try {
            const response = await api.post('candidate/send-reset-password-link/', payload)
            return response.data
        }
        catch(error:any) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const postResetPassword = createAsyncThunk(
    'auth/postResetPassword',
    async (payload: any, {rejectWithValue}) => {
        try {
            const response = await api.post('candidate/reset-password/', payload)
            return response.data;
        }
        catch(error: any) {
            let allErrors: string[] = []
            if (error.response && error.response.data) {
                for (let key in error.response.data) {
                    allErrors = allErrors.concat(error.response.data[key])
                }
            }

            return rejectWithValue(allErrors)
        }
    }
)

export const { resetResetPassword } = authSlice.actions;
export default authSlice.reducer;