import { profile } from 'console';
import React from 'react'
import {useSelector} from "react-redux"
import {Navigate, useLocation} from "react-router-dom"

const UserLoggedInRoute = ({ children }: { children: JSX.Element }) => {
    const user = useSelector((state: any) => state.candidate.userProfile);
    const isAuthenticated = localStorage.getItem('access_token');

    let location = useLocation();

    if(!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location}} replace />
    }
    return children;

};

export default UserLoggedInRoute;