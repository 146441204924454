import React, { FC, useEffect, useState } from 'react';
import styles from './file-upload.module.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';
import api, { apiAuthorizationFile } from '../../../../app/api';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import { uploadCVFile, uploadFile } from '../../logic/candidateSlice';
import { FileUploadModes } from '../../logic/models';

export interface FileUploadProps {
  fileUploadMode: FileUploadModes,
  isFileUploadVisible: boolean,
  setIsFileUploadVisible: Function
}

const FileUpload: FC<FileUploadProps> = (props: FileUploadProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let isOpen = false;
    if (props.isFileUploadVisible != null)  {
      isOpen = props.isFileUploadVisible;
    }
    setOpen(isOpen);

  }, [props.isFileUploadVisible])

  const handleClose = () => {
    if (props.setIsFileUploadVisible) {
      props.setIsFileUploadVisible(false)
  }
    else {
      setOpen(false);
    }
  };

  const [selectedFile, setSelectedFile] = useState<string | Blob | null>(null);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = (e: any) => {
    e.preventDefault();
    if (selectedFile) {
      handleClose();
      if (props.fileUploadMode === FileUploadModes.CV) {
        dispatch(uploadCVFile({file: selectedFile}));
      }
      else {
        dispatch(uploadFile({file: selectedFile}));
      }
    }
  };

  return (
    <div className={styles.FileUpload}>
     <Dialog open={open} onClose={handleClose} fullWidth>
     <form>

      <DialogTitle>Edit Profile</DialogTitle>
      <DialogContent>
        {/* First name */}
        <FormControl fullWidth variant="standard" sx={{ minWidth: 220, marginBottom: '20px' }} size="small">
          <input type="file" onChange={handleFileChange} />
        </FormControl>

       </DialogContent>
       <DialogActions>
         <Button onClick={handleClose}>Cancel</Button>
         <Button onClick={handleFileUpload}>Upload</Button>
       </DialogActions>
     </form>
   </Dialog>
    </div>
 );
};

export default FileUpload;
