import React, { FC } from 'react';
import styles from './breadcrumb.module.scss';
import { Link } from 'react-router-dom';

export interface BreadcrumbProps {
  title: string,
  meta: string
}

const Breadcrumb: FC<BreadcrumbProps> = (props: BreadcrumbProps) => {
  const {title, meta} = props;
  return (
    <section className="page-title">
      <div className="auto-container">
        <div className="title-outer">
          <h1>{title}</h1>
          <ul className="page-breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>{meta}</li>
          </ul>
        </div>
      </div>
    </section>
  );
};


export default Breadcrumb;
