import React, { FC, useEffect, useState } from 'react';
import styles from './skills-table-form.module.scss';
import { ICandidateSkill, ISkill, ITaxonomyItem, ITerm } from '../../logic/models';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDetails, postSkills, selectTaxonomyProficiency, selectUserProfile } from '../../logic/candidateSlice';
import { AppDispatch } from '../../../../app/store';
import SkillAutocomplete from '../../../../components/skill-autocomplete/skill-autocomplete';
import { MenuItem, Select } from '@mui/material';

interface SkillsTableFormProps {}
interface ICandidateSkillSave {
  skill_id:number;
  proficiency_id?:number;
}
const SkillsTableForm: FC<SkillsTableFormProps> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [candidateSkills, setCandidateSkills] = useState<ICandidateSkill[]>([]);
  const taxonomyProficiency = useSelector(selectTaxonomyProficiency);
  const user = useSelector(selectUserProfile);

  useEffect(() => {
    dispatch(getProfileDetails())
  }, []);

  useEffect(() => {
    if (user && user.candidate) {
      setCandidateSkills(user.candidate.skill);  
    }
    else {
      setCandidateSkills([]);
    }
  }, [user])
  const deleteCandidateSkill = (candidateSkillItem: ICandidateSkill) => {
    const newCandidateSkills = candidateSkills.filter((item) => item.id !== candidateSkillItem.id);
    setCandidateSkills(newCandidateSkills);
  }
  
  const skillsAutocompleteChange = (values: ISkill[]) => {
    const newCandidateSkills: ICandidateSkill[] = [];
    for (let skill of values) {
      let newCandidateSkill: ICandidateSkill = {
        skill_id: skill.id,
        skill: skill,
      }
      for (let candidateSkill of candidateSkills) {
        if (candidateSkill.skill.id === skill.id) {
          newCandidateSkill.proficiency = candidateSkill.proficiency;
          newCandidateSkill.proficiency_id = candidateSkill.proficiency_id;
        }
      }
      newCandidateSkills.push(newCandidateSkill);
    }
    setCandidateSkills(newCandidateSkills);
  }

  const onProficiencyChange = (candidateSkillItem: ICandidateSkill, value: any) => {
    let proficiency: ITaxonomyItem | undefined; 
    for (let term of taxonomyProficiency) {
      if (term.id === value) {
        proficiency = term;
      }
    }
    let newCandidateSkills: ICandidateSkill[] = [];
    for (let i = 0; i < candidateSkills.length; i++) {
      newCandidateSkills.push({...candidateSkills[i]});
      if (newCandidateSkills[i].skill.id === candidateSkillItem.skill.id) {
        newCandidateSkills[i].proficiency = proficiency;
        newCandidateSkills[i].proficiency_id = proficiency?.id;
      }
    }
    setCandidateSkills(newCandidateSkills);
  }


  const handleSubmit = () => {
    const values: ICandidateSkillSave[] = candidateSkills.map(item => {
      return {
        skill_id: item.skill.id, 
        proficiency_id: item.proficiency?.id
      };
    })
    dispatch(postSkills(values));
  } 

  return (
    <section>
      <div className="skill-table-autocomplete">
        <SkillAutocomplete
          value={candidateSkills.map((candidateSkillItem) => candidateSkillItem.skill)}
          multiple={true}
          onChange={skillsAutocompleteChange}
        ></SkillAutocomplete>
      </div>
      <div>
        {candidateSkills.map((candidateSkillItem:ICandidateSkill) => {
          return (
            <div className="container-fluid">
            <div key={candidateSkillItem.skill_id} className="row skill-table-row">
              <div className="skill col-sm-12 col-md-8">
                {candidateSkillItem.skill?.name}
              </div>
              <div className="proficiency col-sm-10 col-md-3">
                <Select
                  fullWidth
                  name="proficiency"
                  variant="standard"
                  disableUnderline={true}
                  value={candidateSkillItem.proficiency ? candidateSkillItem.proficiency.id : -1}
                  onChange={(e) => { onProficiencyChange(candidateSkillItem, e.target.value); }}
                >
                  <MenuItem value="-1">
                    <span className="proficiency-dropdown-option">Add Proficiency Level</span>
                  </MenuItem>

                  {taxonomyProficiency.map((proficiency) => (
                      <MenuItem key={proficiency.id} value={proficiency.id}>{proficiency.name}</MenuItem>
                    )
                  )}
                </Select>
              </div>
              <div className="action col-sm-2 col-md-1 d-flex justify-content-center align-items-center">
                <div className="option-box">
                  <ul className="option-list">
                    <li className="m-0">
                      <button data-text="Delete" onClick={() => { deleteCandidateSkill(candidateSkillItem) }}>
                        <span className="la la-trash"></span>
                      </button>
                    </li>
                  </ul>
                </div>
                
              </div>
            
            </div>
            </div>
          );
        })}
      </div>
      <button className="theme-btn btn-style-one mb-5 mt-3" type="submit" onClick={() => {handleSubmit()}}>Update Skills</button>

    </section>
  );
};

export default SkillsTableForm;
