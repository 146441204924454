import React, { FC } from 'react';
import styles from './block-icon-text-items.module.scss';

export interface IBlockIconTextItemsContent {
  id: number,
  icon: string,
  title: string,
  text: string,
  bgClass: string,
}
export interface IBlockIconTextItemsProps {
  blockContent: IBlockIconTextItemsContent[]
}
const BlockIconTextItems: FC<IBlockIconTextItemsProps> = (props: IBlockIconTextItemsProps) => {
  const {blockContent} = props;
  return (
    <>
      {blockContent.map((item) => (
        <div className="col-lg-4 col-md-6 col-sm-12" key={item.id}>
          <div className="work-block -type-2 mb-0">
            <div className="inner-box">
              <div className={`icon-wrap ${item.bgClass}`}>
                <span className={`icon ${item.icon}`}></span>
              </div>
              <h5>{item.title}</h5>
              <p>{item.text}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default BlockIconTextItems;
