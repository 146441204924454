import React, { FC, useEffect } from 'react';
import styles from './page.module.scss';
import { getPageDetails, selectPageDetails } from '../logic/pageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch } from '../../../app/store';

interface PageProps {}



const Page: FC<PageProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  let { slug } = useParams();
  const pageDetails  = useSelector(selectPageDetails);

  useEffect(() => {
    if (slug) {
      dispatch(getPageDetails(slug));
    }
  }, [slug])

  return (
    <div className={styles.Page + ' general-details'}>
      <h1>{pageDetails?.title}</h1>
      {pageDetails?.body ? <div dangerouslySetInnerHTML={{__html: pageDetails?.body}}></div> : ''}

    </div>
  )
};

export default Page;
