import React, { FC } from 'react';
import styles from './taxonomy-tags.module.scss';
import { ITerm } from '../../features/candidate/logic/models';

export interface TaxonomyTagsProps {
  title: string,
  terms: ITerm[]
}

const TaxonomyTags: FC<TaxonomyTagsProps> = (props) => {
  const {title, terms} = props;
  return (
    <div className={styles.TaxonomyTags}>
      
      { terms.length > 0 ? 
        <>
          <h4 className="widget-title mb-3 mt-4">{title}</h4>
          <div className="widget-content">
            <ul className="job-skills">
              {terms.map((termItem: ITerm, i) => (
                <li key={termItem.id}>
                  <span>{termItem.name}</span>
                </li>
              ))}
            </ul>
          </div> 
        </>
      : ''}
    </div>
  );
}

export default TaxonomyTags;
