import { IJobLocation } from "../features/job/details/logic/models";
import { IFilterItem, IFiltersApplied, TFilterItems } from "../features/job/search/logic/models";

export const filterToQueryString = (options: IFiltersApplied) => {
    const SEARCH_KEYWORD = 'search';
    const filterMap: any = {};
    options.filters.map((filter: IFilterItem) => {
      let key: string = SEARCH_KEYWORD;
      let value: any = filter;
      if (typeof filter !== 'string') {
        key = filter.type;
        value = filter.value.toString();
      }
      if (!filterMap[key]) {
        filterMap[key] = [];
      }
      filterMap[key].push(value);
    })
    const qs = [];
    for (let key in filterMap) {
      qs.push(key + '=' + filterMap[key].join(','));
    }
    if (options.page) {
      qs.push('page' + '=' + options.page);
    }

    if (options.recommended) {
      qs.push('recommended' + '=' + (options.recommended ? 1 : 0));
    }
    return qs.join('&');
}

export function timeAgo(dateStr: string) {
  const dateObj: any = new Date(dateStr);
  const now: any = new Date();

  const seconds = Math.floor((now - dateObj) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 365) {
      return dateObj.toLocaleDateString('en-US', {
          year: 'numeric', month: 'long', day: 'numeric'
      });
  } else if (days > 1) {
      return `${days} days ago`;
  } else if (days === 1) {
      return '1 day ago';
  } else if (hours > 1) {
      return `${hours} hours ago`;
  } else if (hours === 1) {
      return '1 hour ago';
  } else if (minutes > 1) {
      return `${minutes} minutes ago`;
  } else if (minutes === 1) {
      return '1 minute ago';
  } else {
      return 'just now';
  }
}


export function replaceFilters (filterTypes: string[], currentFilters:any, newValues: any) {
  const alteredFilters:any  = [];

  const uniqueKeys = [];
  for (let i = 0; i < newValues.length; i++) {
    alteredFilters.push(newValues[i]);
    if (typeof(newValues[i]) !== 'string') {
      uniqueKeys.push(newValues[i].type + '_' + newValues[i].value);
    }
  }
  for (let i = 0; i < currentFilters.length; i++) {
    if (typeof currentFilters[i] === 'string') {
      if (filterTypes.indexOf('search') !== -1) {
        continue
      }
      else {
        alteredFilters.push(currentFilters[i]);
      }
    }
    else if (filterTypes.indexOf(currentFilters[i].type) > -1) {
      continue;
    }
    else {
      const itemUniqueKey  = currentFilters[i].type + '_' + currentFilters[i].value;
      if (uniqueKeys.indexOf(itemUniqueKey) === -1) {
        uniqueKeys.push(itemUniqueKey);
        alteredFilters.push(currentFilters[i]);
      }
    }
  }
  
  return alteredFilters;
}


export function formatDate(date: any) {
  if (!date) {
    return '';
  }
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  return year;
}
export function convert_locations_to_string_array(locations: IJobLocation[] | undefined) {
  let locationStrings = [];
  if (locations) {
    for(let i = 0; i < locations.length; i++) {
      let tmpLocation = [];
      const locationItem = locations[i];
      if (typeof locationItem === 'string') {
        locationStrings.push(locationItem);
      }
      else {
        if (locationItem.city) {
          tmpLocation.push(locationItem.city.name)
        }
        if (locationItem.state) {
          tmpLocation.push(locationItem.state.name)
        }
        if (locationItem.country) {
          tmpLocation.push(locationItem.country.name)
        }
        if(tmpLocation.length) {
          locationStrings.push(tmpLocation.join(', '));
        }
      }
    }
  }
  return locationStrings;
}