
import React from 'react'
import {useSelector} from "react-redux"
import {Navigate, useLocation} from "react-router-dom"

const UserAnonymousRoute = ({ children }: { children: JSX.Element }) => {
    const user = useSelector((state: any) => state.user);
    const isAuthenticated = localStorage.getItem('access_token');

    let location = useLocation();

    if(user) {
        return <Navigate to="/login" state={{ from: location}} replace />
    }
    return children;

};

export default UserAnonymousRoute;