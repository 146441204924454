import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { ICandidate, ICity, ICountry, IJobApplication, ITaxonomies, IUser } from "./models";
import api, { apiAuthorization, apiAuthorizationFile } from "../../../app/api";

export interface IUserTokens {
    access: string | null,
    refresh: string | null
}
export interface ICandidateState {
    userProfile: IUser | null,
    tokens: IUserTokens | null, 
    taxonomies: ITaxonomies,
    countries: ICountry[],
    cities: ICity[],
    applications: IJobApplication[],
    isLoading: boolean,
    resetApplicationCreated: boolean
}
const initialState: ICandidateState = {
    userProfile: null,
    tokens: {
        access: localStorage.getItem('access_token'),
        refresh: localStorage.getItem('refresh_token')
    },
    taxonomies: {
        ages: [],
        degrees: [],
        availability: [],
        pronounce: [],
        proficiency: [],
        language_proficiency: [],
        languages: [],
        industries: [],
    },
    countries: [],
    cities: [],
    applications: [],
    isLoading: false,
    resetApplicationCreated: false,
}

export const selectCandidateProfile = (state: RootState) => state.candidate;
export const selectUserProfile = (state: RootState) => state.candidate.userProfile;
export const selectUserTokens = (state: RootState) => state.candidate.tokens;
export const selectTaxonomyProficiency = (state: RootState) => state.candidate.taxonomies.proficiency;
export const selectTaxonomyLanguageProficiency = (state: RootState) => state.candidate.taxonomies.language_proficiency;
export const selectTaxonomyLanguages = (state: RootState) => state.candidate.taxonomies.languages;
export const selectTaxonomyDegrees = (state: RootState) => state.candidate.taxonomies.degrees;
export const selectTaxonomyIndustries = (state: RootState) => state.candidate.taxonomies.industries;
export const selectTaxonomyWorkAvailability = (state: RootState) => state.candidate.taxonomies.availability;
export const selectTaxonomyPronounce = (state: RootState) => state.candidate.taxonomies.pronounce;
export const selectTaxonomyAge = (state: RootState) => state.candidate.taxonomies.ages;
export const selectCountries = (state: RootState) => state.candidate.countries;
export const selectCities = (state: RootState) => state.candidate.cities;

export const candidateSlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {
        resetApplicationCreatedState: (state, action) => {
            state.resetApplicationCreated = false
        },
        setTokens: (state, action) => {
            localStorage.setItem('access_token', action.payload.access_token);
            localStorage.setItem('refresh_token', action.payload.refresh_token);
            state.tokens = action.payload;
        },
        userLogout: (state) => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            state.userProfile = null;
            state.applications = [];
            state.tokens = {
                access: null,
                refresh: null
            };
            console.log('userLogout', state)
        }
    },
    extraReducers(builder) {
        builder.addCase(getProfileDetails.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(getProfileDetails.rejected, (state, action) => {
            // todo handle rejection
            state.isLoading = false;
        })
        .addCase(getProfileDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userProfile = action.payload;
        })

        .addCase(getTaxonomies.fulfilled, (state, action) => {
            state.isLoading = false;
            state.taxonomies = action.payload;
        })


        .addCase(getCountries.fulfilled, (state, action) => {
            state.countries = action.payload;
        })


        .addCase(getCities.fulfilled, (state, action) => {
            state.cities = action.payload;
        })


        .addCase(getApplications.fulfilled, (state, action) => {
            state.isLoading = false;
            state.applications = action.payload;
        })
        .addCase(postApplyJob.pending, (state, action) => {
            state.isLoading = true;
        })

        .addCase(postApplyJob.rejected, (state, action) => {
            state.isLoading = false;
        })

        .addCase(postApplyJob.fulfilled, (state, action) => {
            state.isLoading = false;
            state.resetApplicationCreated = true

        })
        .addCase(postSkills.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.skill
            ) {
                state.userProfile.candidate.skill = action.payload;
            }
        })

        .addCase(postSkill.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.skill
            ) {
                state.userProfile.candidate.skill.push(action.payload);
            }
        })

        .addCase(patchSkill.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.skill
            ) {
                for(let i in state.userProfile.candidate.skill) {
                    const skill = state.userProfile.candidate.skill[i];
                    if (skill.id === action.payload.id) {
                        state.userProfile.candidate.skill[i] = action.payload;
                    }
                }
            }
        })
        .addCase(deleteSkill.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.skill
            ) {
                state.userProfile.candidate.skill = state.userProfile?.candidate?.skill.filter((skill)=> skill.id !== action.payload?.deleted_id)
            }
        })




        .addCase(postLanguage.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.language
            ) {
                state.userProfile.candidate.language.push(action.payload);
            }
        })

        .addCase(patchLanguage.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.language
            ) {
                for(let i in state.userProfile.candidate.language) {
                    const language = state.userProfile.candidate.language[i];
                    if (language.id === action.payload.id) {
                        state.userProfile.candidate.language[i] = action.payload;
                    }
                }
            }
        })
        .addCase(deleteLanguage.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.language
            ) {
                state.userProfile.candidate.language = state.userProfile?.candidate?.language.filter((language)=> language.id !== action.payload?.deleted_id)
            }
        })



        .addCase(postEducation.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.education
            ) {
                state.userProfile.candidate.education.push(action.payload);
            }
        })

        .addCase(patchEducation.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.education
            ) {
                for(let i in state.userProfile.candidate.education) {
                    const language = state.userProfile.candidate.education[i];
                    if (language.id === action.payload.id) {
                        state.userProfile.candidate.education[i] = action.payload;
                    }
                }
            }
        })
        .addCase(deleteEducation.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.education
            ) {
                state.userProfile.candidate.education = state.userProfile?.candidate?.education.filter((language)=> language.id !== action.payload?.deleted_id)
            }
        })


        .addCase(postExperience.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.experience
            ) {
                state.userProfile.candidate.experience.push(action.payload);
            }
        })

        .addCase(patchExperience.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.experience
            ) {
                for(let i in state.userProfile.candidate.experience) {
                    const language = state.userProfile.candidate.experience[i];
                    if (language.id === action.payload.id) {
                        state.userProfile.candidate.experience[i] = action.payload;
                    }
                }
            }
        })
        .addCase(deleteExperience.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.experience
            ) {
                state.userProfile.candidate.experience = state.userProfile?.candidate?.experience.filter((language)=> language.id !== action.payload?.deleted_id)
            }
        })
        .addCase(patchIndustries.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.industry
            ) {
                state.userProfile.candidate.industry = action.payload.candidate.industry;
            }
        })
        .addCase(patchCandidate.fulfilled, (state, action) => {
            state.isLoading = false;
            if (
                state
                && state.userProfile
            ) {
                state.userProfile = action.payload;
            }
        })
        .addCase(uploadFile.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.files
            ) {
                state.userProfile.candidate.files.push(action.payload)
            }
        })
        .addCase(deleteFile.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
                && state.userProfile.candidate.files
            ) {
                state.userProfile.candidate.files = state.userProfile?.candidate?.files.filter((item)=> item.id !== action.payload?.deleted_id)
            }
        })

        .addCase(uploadCVFile.pending, (state, action) => {
            state.isLoading = true;
        })

        .addCase(uploadCVFile.rejected, (state, action) => {
            state.isLoading = false;
        })

        .addCase(uploadCVFile.fulfilled, (state, action) => {
            state.isLoading = false;

            if (
                state
                && state.userProfile
                && state.userProfile.candidate
            ) {
                state.userProfile.candidate = action.payload;
            }
        })
    },
})


export const getProfileDetails = createAsyncThunk(
    'candidate/getProfileDetails',
    async( _ , { rejectWithValue }) => {
        try {
            const response = await apiAuthorization.get('candidate/profile');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)


export const getTaxonomies = createAsyncThunk(
    'candidate/getTaxonomies',
    async( _ , { rejectWithValue }) => {
        try {
            const response = await api.get('candidate/details/taxonomies');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const getCountries = createAsyncThunk(
    'taxonomy/getCountries',
    async ( _, {rejectWithValue}) => {
        try {
            const response = await api.get('taxonomy/country');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const getCities = createAsyncThunk(
    'taxonomy/getCities',
    async (payload: any, {rejectWithValue}) => {
        try {
            const response = await api.get('taxonomy/city?country_id' + payload.country_id +'&name=' + payload.partial_city_name);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const postSkills = createAsyncThunk(
    'candidate/postSkills',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.post('candidate/details/skills/', payload);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const postSkill = createAsyncThunk(
    'candidate/postSkill',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.post('candidate/details/skill/', payload);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const patchSkill = createAsyncThunk(
    'candidate/patchSkill',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.patch('candidate/details/skill/' + payload.id + '/', payload);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const deleteSkill = createAsyncThunk(
    'candidate/deleteSkill',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.delete('candidate/details/skill/' + payload.id + '/');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)



export const postLanguage = createAsyncThunk(
    'candidate/postLanguage',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.post('candidate/details/language/', payload);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const patchLanguage = createAsyncThunk(
    'candidate/patchLanguage',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.patch('candidate/details/language/' + payload.id + '/', payload);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const deleteLanguage = createAsyncThunk(
    'candidate/deleteLanguage',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.delete('candidate/details/language/' + payload.id + '/');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
);



export const postEducation = createAsyncThunk(
    'candidate/postEducation',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.post('candidate/details/education/', payload);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const patchEducation = createAsyncThunk(
    'candidate/patchEducation',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.patch('candidate/details/education/' + payload.id + '/', payload);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const deleteEducation = createAsyncThunk(
    'candidate/deleteEducation',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.delete('candidate/details/education/' + payload.id + '/');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
);


export const postExperience = createAsyncThunk(
    'candidate/postExperience',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.post('candidate/details/experience/', payload);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const patchExperience = createAsyncThunk(
    'candidate/patchExperience',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.patch('candidate/details/experience/' + payload.id + '/', payload);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const deleteExperience = createAsyncThunk(
    'candidate/deleteExperience',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.delete('candidate/details/experience/' + payload.id + '/');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
);

export const getApplications = createAsyncThunk(
    'candidate/getApplications',
    async(_,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.get('candidate/details/application/');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const postApplyJob = createAsyncThunk(
    'candidate/postApplyJob',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.post('candidate/details/application/', payload);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const patchIndustries = createAsyncThunk(
    'candidate/patchIndustries',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.patch('candidate/details/edit/', payload);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const patchCandidate = createAsyncThunk(
    'candidate/patchCandidate',
    async(payload: any,{ rejectWithValue }) => {
        try {
            const response = await apiAuthorization.patch('candidate/details/edit/', payload);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const uploadCVFile = createAsyncThunk(
    'candidate/uploadCVFile',
    async(payload: any, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('cv_file', payload.file);
            const response = await apiAuthorizationFile.patch('/candidate/details/upload/cv/', formData);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
);

export const uploadFile = createAsyncThunk(
    'candidate/uploadFile',
    async (payload: any, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('file', payload.file);
            const response = await apiAuthorizationFile.post('/candidate/details/file/', formData);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
);

export const deleteFile = createAsyncThunk(
    'candidate/deleteFile',
    async(payload: any, { rejectWithValue }) => {
        try {
            const response = await apiAuthorization.delete('candidate/details/file/' + payload.id +'/');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
);

export const changePassword = createAsyncThunk(
    'candidate/changePassword',
    async(payload: any, { rejectWithValue }) => {
        try {
            const response = await api.post('candidate/change-password/', payload);
            return response.data;
        }   
        catch(error) {
            return rejectWithValue('Error');
        }
    }  
);

export const { resetApplicationCreatedState, userLogout } = candidateSlice.actions;
export default candidateSlice.reducer;