import backgroundImage from '../../assets/images/resource/banner-img-3.png'
const ImageBox = () => {
  return (
    <div className="image-box">
      <figure className="main-image" data-aos-delay="1500" data-aos="fade-left">
        <img src={backgroundImage} alt="banner-img" />
      </figure>
    </div>
  );
};

export default ImageBox;
