import React, { FC, useEffect, useState } from 'react';
import styles from './reset-password-form.module.scss';
import * as yup from 'yup'
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../app/store';
import { Button, FormControl, TextField } from '@mui/material';
import { Password } from '@mui/icons-material';
import { Link, useSearchParams } from 'react-router-dom';
import { postResetPassword, selectResetPassword } from '../logic/authSlice';
import ModalWrapper from '../../../components/modal-wrapper/modal-wrapper';
import 'bootstrap';

import * as bootstrap from 'bootstrap';
interface ResetPasswordFormProps {}

const validationSchema = yup.object({
  password: yup.string().min(8, "Must Contain 8 Characters").required()
    .matches(
      /^(?=.*[a-z])/,
      " Must Contain One Lowercase Character"
    )
    .matches(
      /^(?=.*[A-Z])/,
      "  Must Contain One Uppercase Character"
    )
    .matches(
      /^(?=.*[0-9])/,
      "  Must Contain One Number Character"
    ),
    password_confirm: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match')
})


const ResetPasswordForm: FC<ResetPasswordFormProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const {isLoading, errorMessages, showSuccess} = useSelector(selectResetPassword);
  const initialValues = {
    password: null,
    password_confirm: null
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setMyModal(new bootstrap.Modal(('#reset-password-form'), {}))

      const sendValues = {
          "password": values.password,
          "user_id": searchParams.get('user_id'),
          "timestamp": searchParams.get('timestamp'),
          "signature": searchParams.get('signature')
      }
      dispatch(postResetPassword(sendValues));
    }
  })  

  const [myModal, setMyModal] = useState<any>();

  useEffect(() => {
    if (myModal && showSuccess) {
      myModal.show()
    }
  }, [myModal, showSuccess])

  return (
    <div className={styles.ResetPassword}>
      <div>
        { errorMessages ? <ul className='text-error'>
          {errorMessages.map((item, index) => <li key={index}>{item}</li>) }
        </ul> : ''
        }
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth variant='standard' sx= {{minWidth: 220, marginTop: '50px', marginBottom: '20px'}} size="small">
          
          <TextField
              label="Password"
              variant="standard"
              type="password"
              name="password"
              className={styles.TextField}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={(formik.touched.password && Boolean(formik.errors.password)) ? formik.errors.password : null }
            />

            <br/>

            <TextField
              label="Confirm Password"
              variant="standard"
              type="password"
              name="password_confirm"
              className={styles.TextField}
              onChange={formik.handleChange}
              error={formik.touched.password_confirm && Boolean(formik.errors.password_confirm)}
              helperText={(formik.touched.password_confirm && Boolean(formik.errors.password_confirm)) ? formik.errors.password_confirm : null }
            />
          </FormControl>
          <button
            type="submit"
            className="theme-btn btn-style-one"
          >Reset password</button>
        </form>
      </div>
      <ModalWrapper id="reset-password-form">
        <>
          <div>
            <div className="text-center mt-4">
              <h3>Success!</h3>
              <p className="mt-3">
                Now you can log in.
              </p>
            </div>
          </div>
        
          <br />
          <br />
          <br />
          <Link
            className="theme-btn btn-style-one w-percent-100 py-4 mt-2 mb-5" 
            to="/login"
            onClick={() => {
              if (myModal) {
                myModal.hide()
              }
            }}
          >
            Go To Login Page
          </Link>
        </>
      </ModalWrapper>
    </div>
  )
};

export default ResetPasswordForm;

