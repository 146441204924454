import React, { FC, useEffect, useState } from 'react';
import styles from './job-head.module.scss';
import { IJobDetails, IJobListItem } from '../../details/logic/models';
import { Link } from 'react-router-dom';
import { convert_locations_to_string_array, timeAgo } from '../../../../app/helper';

export interface JobHeadProps {
  details: IJobDetails | IJobListItem,
  isLink?: boolean,
  children?: JSX.Element, 
  classNameLetter?: string
}

const JobHead: FC<JobHeadProps> = (props) => {
  const {
    isLink,
    classNameLetter,
    children
  } = props;
  const {
    id,
    title,
    updated_at,
    locations,
    company
  } = props.details;

  let [ datePostedString, setDatePostedString] = useState('');
  useEffect(() => {
    datePostedString = '';
    if (updated_at) {
      datePostedString = timeAgo(updated_at);
    }
    setDatePostedString(datePostedString);
  }, [updated_at])

  let [locationStrings, setLocationsStrings] = useState<string>();
  useEffect(() => {
    var locationStrings = convert_locations_to_string_array(locations);
    
    setLocationsStrings(locationStrings.join('; '));
  }, [locations]);

  return (
      <div className="content">
        <span className="company-logo">
          {
            company && company.logo ? 
            <img src={company.logo} alt="logo" />
            : 
            <span className={ (classNameLetter ? classNameLetter : 'style-1') + " job-title-letter"}>{title[0]}</span>
          }
        </span>
        <div>
          
        { isLink ? 
            <h4>{title}</h4>
            : 
              <h4>
              <Link target="_blank" to={`/job/${id}`}>{title}</Link>
            </h4>
        }
        <ul className="job-info">
          { company ? <li>
            <span className="icon flaticon-briefcase"></span>
            {company.name}
          </li>
          : ''}
          {/* compnay info */}

          { locationStrings && locationStrings.length > 0 ? 
            <li>
              <span className="icon flaticon-map-locator"></span>
              { locationStrings }

            </li>
          :'' }
          {/* location info */}
          
          { datePostedString ?
            <li>
              <span className="icon flaticon-clock-3"></span>
              {datePostedString}
            </li>
          : ''}
          {/* time info */}
        </ul>
        {/* End .job-info */}
        </div>
        {children}
      </div>
  )
}

export default JobHead;
