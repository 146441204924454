export const dataHowItWorks = [
    {
      id: 1,
      icon: "icon-case",
      title: "Register an account to start",
      text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor`,
      bgClass: "-blue",
    },
    {
      id: 2,
      icon: "icon-contact",
      title: "Explore over thousands of jobs",
      text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor`,
      bgClass: "-red",
    },
    {
      id: 3,
      icon: "icon-doc",
      title: "Find the most suitable job for you",
      text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor`,
      bgClass: "-yellow",
    },
];