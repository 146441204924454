import React, { FC } from 'react';
import styles from './semiscreen.module.scss';

import backgroundImage from '../../../assets/images/background/12.jpg'
import MobileMenu from '../header/components/mobile-menu/mobile-menu';
import HeaderDark from '../header/header-dark/header-dark';

export interface SemiscreenProps {
  children?: string | JSX.Element | JSX.Element[]

}

const Semiscreen: FC<SemiscreenProps> = (props) => {
  return (
    <>
      <HeaderDark />
      {/* <!--End Main Header --> */}

      <MobileMenu />
      {/* End MobileMenu */}
      

      <div className="login-section">
        <div
          className="image-layer"
          style={{ backgroundImage: "url(" + backgroundImage + ")" }}
        ></div>
        <div className="outer-box">
          {props.children}
        </div>
      </div>
      {/* <!-- End Info Section --> */}
    </>
  );
}


export default Semiscreen;
