import React, { FC } from 'react';
import styles from './dashboard-sidebar.module.scss';
import "react-circular-progressbar/dist/styles.css";
import { isActiveLink } from "../../utils/linkActiveChecker";
import { useDispatch, useSelector } from "react-redux";
import { userDropdownMenuItems } from '../layout/header/components/user-dropdown/logic/menu';
import { Link } from 'react-router-dom';
import { menuToggle } from './logic/toogleSlice';
import ResumeStatus from '../../features/candidate/components/resume-status/resume-status';
import { userLogout } from '../../features/candidate/logic/candidateSlice';


interface DashboardSidebarProps {}


const DashboardSidebar = () => {
  const { menu } = useSelector((state: any) => state.toggle);
  const percentage = 30;
  const currentPath = window.location.pathname;


  const dispatch = useDispatch();
  // menu togggle handler
  const menuToggleHandler = () => {
    dispatch(menuToggle());
  };

  return (
    <div className={`user-sidebar ${menu ? "sidebar_open" : ""}`}>
      {/* Start sidebar close icon */}
      <div className="pro-header text-end pb-0 mb-0 show-1023">
        <div className="fix-icon" onClick={menuToggleHandler}>
          <span className="flaticon-close"></span>
        </div>
      </div>
      {/* End sidebar close icon */}

      <div className="sidebar-inner">
        <ul className="navigation">
          {userDropdownMenuItems.map((item) => (
            <li
              className={`${
                isActiveLink(item.routePath, currentPath) ? "active" : ""
              } mb-1`}
              key={item.id}
              onClick={menuToggleHandler}
            >
              <Link to={item.routePath} onClick={(e:any) => {
                const logout_id = 11;
                if(item.id === logout_id) {
                  dispatch(userLogout());
                }
              }}>
                <i className={`la ${item.icon}`}></i> {item.name}
              </Link>
            </li>
          ))}
        </ul>
        {/* End navigation */}
        
        <ResumeStatus/>
      </div>
    </div>
  );
};


export default DashboardSidebar;
