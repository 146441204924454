import React, { FC, useEffect, useState } from 'react';
import styles from './user-contact-form.module.scss';
import * as yup from 'yup';
import { ICity, ICountry } from '../../logic/models';
import { Autocomplete, TextField, FormControl, FormControlLabel, Checkbox, MenuItem, Select } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import { selectTaxonomyWorkAvailability, selectTaxonomyPronounce, selectCountries, selectUserProfile, getCountries, getProfileDetails, getTaxonomies, patchCandidate } from '../../logic/candidateSlice';
import CityAutocomplete from '../../../../components/city-autocomplete/city-autocomplete';

interface UserContactFormProps {}

const validationSchema = yup.object({
  phone: yup
    .number(),
  city_id: yup
    .number(),
    // .required(),
  country_id: yup
    .number()
});
interface ICandidateValues {
    id? : number;
    country_id?: number | null;
    country?: ICountry | null;
    city_id?: number | null;
    city?: ICity | null;
    phone?: string | null | undefined;
  }

const UserContactForm: FC<UserContactFormProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  
  const taxonomyCountries = useSelector(selectCountries);

  const [country, setCountry] = useState<ICountry | null>(null);
  const [city, setCity] = useState<ICity | null>(null);
  const user = useSelector(selectUserProfile);
  
  useEffect(() => {
    dispatch(getCountries())
    dispatch(getProfileDetails())
    dispatch(getTaxonomies())
  }, []);

  const initialValues:ICandidateValues = {
    country_id: null,
    city_id: null,
    city: null,
    phone: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values) {
        const sendValues = values;
        dispatch(patchCandidate(sendValues));
      }
    }
  })

  useEffect(() => {
    const userValues: ICandidateValues = {
      country: user?.candidate?.country,
      country_id: user?.candidate?.country?.id,
      city_id: user?.candidate?.city?.id,
      city: user?.candidate?.city,
      phone: user?.candidate?.phone || '',
    }
    formik.setValues(userValues);

    if (user?.candidate?.city) {
      setCity(user.candidate.city);
    }
    else {
      setCity(null);
    }
  }, [user])

  useEffect(() => {
    if (country) {
      formik.setFieldValue("country", country)
      formik.setFieldValue('country_id', country.id);
    }
  }, [country])

  useEffect(() => {
    if(city) {
      formik.setFieldValue("city", city)
      formik.setFieldValue('city_id', city.id);
      if (!formik.values.country_id) {
        setCountry(city.country);
      }
    }
  }, [city])

   return (
      <div className={styles.UserForm}>
       <form  onSubmit={formik.handleSubmit} className="default-form">
          <div className="row">
        
            {/* Phone number */}
            <div className="form-group col-lg-4 col-md-12">
                <label>Phone</label>

                <TextField
                  fullWidth
                  id="phone-text"
                  name="phone"
                  variant="standard"
                  value={formik.values.phone ? formik.values.phone : ''}
                  onChange={formik.handleChange}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
            </div>
            
            {/* City */}
            <div className="form-group col-lg-4 col-md-12">
              <label>City</label>
              <CityAutocomplete
                formik={formik}
                city={city}
                setCity={setCity}
              ></CityAutocomplete>
            </div>

            {/* Country */}
            <div className="form-group col-lg-4 col-md-12">
              <label>Country</label>
              <Autocomplete
                fullWidth
                disableListWrap
                options={taxonomyCountries}
                getOptionLabel = {(option: ICountry) => {
                  return option.name
                }}

                isOptionEqualToValue ={(option, value) => {
                  return option.id === value.id
                }}

                value={formik.values?.country ? formik.values.country : null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete='off'
                    placeholder="Country"
                    variant="standard"
                    value={formik.values.country_id ? formik.values.country_id : ''}
                    error={formik.touched.country_id && Boolean(formik.errors.country_id)}
                    helperText={(formik.touched.country_id && Boolean(formik.errors.country_id)) ? formik.errors.country_id : null }
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
                renderOption={(props, option: ICountry) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
                onChange={(e, value) => {
                    setCountry(value)
                  }
                }
              />
            </div>
          </div>

           <button className="theme-btn btn-style-one mb-3" type="submit" onClick={() => {formik.handleSubmit()}}>Save</button>
       </form>
      </div>
   );
};

export default UserContactForm;
