import React, { FC } from 'react';
import styles from './education-details.module.scss';
import { ICandidateEducation } from '../../logic/models';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { deleteEducation } from '../../logic/candidateSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import dayjs from 'dayjs';
export interface EducationProps {
  details: ICandidateEducation,
  setEducationFormValues?: Function
}

const EducationDetails: FC<EducationProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = React.useState(false);
  const degreeName = props.details.degree_other ? props.details.degree_other : props.details.degree?.name;
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    if (props.setEducationFormValues) {
      const values: ICandidateEducation = {
        ...props.details,
        skill_id: props.details.skills?.map((skill) => skill.id)
      }

      props.setEducationFormValues(values);
    }
  }

  const handleDelete = () => {
    dispatch(deleteEducation({id:props.details.id}))
  }
  return (
    
    <>
      {/* <!-- Resume BLock --> */}
      <div className="resume-block">
        <div className="inner">
          <span className="name">{ degreeName ? degreeName[0] : '' }</span>
          <div className="title-box">
            <div className="info-box">
              <h3>{degreeName}{ degreeName && props.details.study_field ? ' in ' : ''}{props.details.study_field}</h3>
              <span>{props.details.organization}</span>
            </div>
            <div className="edit-box">
              {
                props.details.from_date_year || props.details.current_education || props.details.to_date_year ?
                  <span className="year">
                    {props.details.from_date_year}
                    {props.details.from_date_year && (props.details.current_education || props.details.to_date_year) ? ' - ': ''}
                    {props.details.current_education ? 'Current' : props.details.to_date_year}
                  </span>
                : ''
              }
              <div className="edit-btns">
                <button>
                  <span className="la la-pencil" onClick={handleEdit}></span>
                </button>
                <button>
                  <span className="la la-trash" onClick={handleClickOpen}></span>
                </button>
              </div>
            </div>
          </div>
          <div className="text"></div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Education removal?
       
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="container">
            <div className="row">
                <div className="form-group col-lg-12 col-md-12 mt-2 mb-4 d-flex justify-content-end">
                  <button onClick={handleClose} className="theme-btn btn-style-eight narrow ">Cancel</button>
                  <button  onClick={handleDelete}  type="submit" className="theme-btn btn-style-one narrow ms-2">Delete</button>
                </div>
              </div>
            </div>
        </DialogActions>
      </Dialog>
    </>
  )
};

export default EducationDetails;
