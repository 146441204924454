import React, { FC } from 'react';
import styles from './call-to-action.module.scss';
import { Link } from 'react-router-dom';
import backgroundImage from '../../assets/images/background/1.jpg';

interface CallToActionProps {}

const CallToAction: FC<CallToActionProps> = () => {
  return (
    <section
      className="call-to-action-two"
      style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="auto-container" data-aos="fade-up">
        <div className="sec-title light text-center">
          <h2>Does this job fits you?</h2>
          <div className="text">
            Apply now, or if it doesn’t sound like your dream job, continue searching, there are more than 5000+ positions available!
          </div>
        </div>

        <div className="btn-box">
          <Link to="/sign-up" className="theme-btn btn-style-two">
            Apply Job Now
          </Link>
          <Link to="/search" className="theme-btn btn-style-five">
            Search Job
          </Link>
        </div>
      </div>
    </section>
  );
}

export default CallToAction;
