import React, { FC } from 'react';
import styles from './about-us.module.scss';
import Header from '../../components/layout/header/header/header';
import MobileMenu from '../../components/layout/header/components/mobile-menu/mobile-menu';
import CallToAction from '../../components/call-to-action/call-to-action';
import Footer from '../../components/layout/footer/footer/footer';
import ImageBox from '../../components/image-box/image-box';
import FunFact from '../../components/fun-fact/fun-fact.lazy';
import TestimonialSection from '../../components/testimonial-section/testimonial-section';
import Partners from '../../components/partners/partners';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import HowItWorks from '../../components/how-it-works/how-it-works';


interface AboutUsProps {}

const AboutUs: FC<AboutUsProps> = () => (
  <>
    
  {/* <!-- Header Span --> */}
  <span className="header-span"></span>
  
  <Header />
  {/* <!--End Main Header --> */}

  <MobileMenu />
  {/* End MobileMenu */}

  <Breadcrumb title="About Us" meta="About Us" />
  {/* <!--End Page Title--> */}
  
  <section className="about-section-three">
    <div className="auto-container">
      <ImageBox />

      {/* <!-- Fun Fact Section --> */}
      <div className="fun-fact-section">
        <div className="row">
          <FunFact />
        </div>
      </div>
      {/* <!-- Fun Fact Section --> */}

      <div className="text-box">
        <h4>About Superio</h4>
        <p>
          Far much that one rank beheld bluebird after outside ignobly allegedly
          more when oh arrogantly vehement irresistibly fussy penguin insect
          additionally wow absolutely crud meretriciously hastily dalmatian a
          glowered inset one echidna cassowary some parrot and much as goodness
          some froze the sullen much connected bat wonderfully on instantaneously
          eel valiantly petted this along across highhandedly much.
        </p>
        <p>
          Repeatedly dreamed alas opossum but dramatically despite expeditiously
          that jeepers loosely yikes that as or eel underneath kept and slept
          compactly far purred sure abidingly up above fitting to strident wiped
          set waywardly far the and pangolin horse approving paid chuckled
          cassowary oh above a much opposite far much hypnotically more therefore
          wasp less that hey apart well like while superbly orca and far hence
          one.Far much that one rank beheld bluebird after outside ignobly
          allegedly more when oh arrogantly vehement irresistibly fussy.
        </p>
      </div>
    </div>
  </section>
  {/* <!-- End About Section Three --> */}
  
  <TestimonialSection></TestimonialSection>
  {/* End Testimonial Section */}


  <section className="work-section style-two">
    <div className="auto-container">
      <div className="sec-title text-center">
        <h2>How It Works?</h2>
        <div className="text">Job for anyone, anywhere</div>
      </div>
      {/* End sec-title */}

      <div className="row" data-aos="fade-up">
        <HowItWorks />
      </div>
    </div>
  </section>
  {/* <!-- End Work Section --> */}

  <section className="clients-section">
      <div className="sponsors-outer" data-aos="fade">
        {/* <!--Sponsors Carousel--> */}
        <ul className="sponsors-carousel">
          <Partners />
        </ul>
      </div>
    </section>
    {/* <!-- End Clients Section--> */}
  <Footer/>
  {/* <!-- End Main Footer --> */}
</>
);

export default AboutUs;
