import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IIndustry } from "../../../features/candidate/logic/models";
import api from "../../../app/api";
import { RootState } from "../../../app/store";
import { IJobListItem } from "../../../features/job/details/logic/models";
import { IPopularSearch } from "../../../components/popular-search/popular-search";

export interface IHomeState {
    jobCount: number,
    jobCountToday: number,
    featuredIndustries: IIndustry[],
    featuredJobPosts: IJobListItem[],
    popularSearch: IPopularSearch[]
}

const initialState: IHomeState = {
    jobCount: 0,
    jobCountToday: 0,
    featuredIndustries: [],
    featuredJobPosts: [],
    popularSearch: []
}
export const selectHomeState = (state:RootState) => state.home;
export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getFeaturedIndustries.fulfilled, (state, action) => {
            state.featuredIndustries = action.payload.results;
            state.jobCount = action.payload.job_posts_count;
            state.jobCountToday = action.payload.job_posts_today_count;
        })
        .addCase(getFeaturedJobPosts.fulfilled, (state, action) => {
            state.featuredJobPosts = action.payload.results;
        })
        .addCase(getPopularSearch.fulfilled, (state, action) => {
            state.popularSearch = action.payload;
        })
    }
})

export const getFeaturedIndustries = createAsyncThunk(
    'home/getFeaturedIndustries',
    async(payload, {rejectWithValue}) => {
        try {
            const response = await api.get('taxonomy/industries/featured');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)
export const getFeaturedJobPosts = createAsyncThunk(
    'home/getFeaturedJobPosts',
    async(payload, {rejectWithValue}) => {
        try {
            const response = await api.get('job/featured');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export const getPopularSearch = createAsyncThunk(
    'home/getPopularSearch',
    async(payload, {rejectWithValue}) => {
        try {
            const response = await api.get('popular-searches');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)
export default homeSlice.reducer;