import React, { FC, useEffect, useState } from 'react';
import styles from './experience-form.module.scss';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Checkbox, FormControlLabel, FormControl, InputLabel, MenuItem, Select, Autocomplete } from '@mui/material';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { patchExperience, postExperience, selectTaxonomyDegrees, selectTaxonomyIndustries } from '../../logic/candidateSlice';
import { ICandidateExperience, ISkill, ITaxonomyItem } from '../../logic/models';
import { AppDispatch } from '../../../../app/store';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SkillAutocomplete from '../../../../components/skill-autocomplete/skill-autocomplete';

export interface ExperienceFormProps {
  isExperienceFormVisible?: boolean,
  setIsExperienceFormVisible?: Function,
  setExperienceFormValues?: Function,
  editExperience?: ICandidateExperience
}

const validationSchema = yup.object({
  company: yup
    .string()
    .required(),
  role: yup
    .string()
    .required(),
  description: yup
    .string(),
  skill_id: yup
    .array()
    .required(),
  current_job: yup.boolean(),
  from_date: yup
    .date()
    .required(),
  to_date: yup
    .date()
    .when('current_job', {
      is: true,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required(),
    })
});

const ExperienceForm: FC<ExperienceFormProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const taxonomyIndustry = useSelector(selectTaxonomyIndustries);
  useEffect(() => {
    if (props.editExperience) {
      let values = {
        ...props.editExperience
      };

      if (values.skills && !values.skill_id) {
        values.skill_id = values.skills.map((skill) => skill.id);
      }

      if (values.industry && !values.industry_id) {
        values.industry_id = values.industry.id;
      }

      formik.setValues(values);
    }
    else {
      formik.setValues(initialValues);
    }
  }, [props.editExperience])

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let isOpen = false;
    if (props.isExperienceFormVisible != null)  {
      isOpen = props.isExperienceFormVisible;
    }
    setOpen(isOpen);

  }, [props.isExperienceFormVisible])
  const initialValues: ICandidateExperience = { to_date: null };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const submitValues: ICandidateExperience = {
        company: values.company,
        from_date: values.from_date,
        to_date: values.to_date,
        current_job: values.current_job ? values.current_job : false,
        description: values.description,
        role: values.role ? values.role : '',
        skill_id: values.skill_id ? values.skill_id : [],
        industry_id: values.industry_id ? values.industry_id : null
      };

      if (values?.id) {
        submitValues['id'] = values.id;
      }
      let fromDateValue = null;
      if (values && values.from_date) {
        if (typeof values.from_date == 'string') {
          fromDateValue = values.from_date;
        }
        else {
          fromDateValue = values.from_date.format('YYYY-MM-DD');
        }
        submitValues['from_date'] = fromDateValue;
      }

      let toDateValue = null;
      if (values && values.to_date) {
        if (typeof values.to_date == 'string') {
          toDateValue = values.to_date;
        }
        else {
          toDateValue = values.to_date.format('YYYY-MM-DD');
        }
        submitValues['to_date'] = toDateValue;
      }

      if (submitValues) {
        if (submitValues.id) {
          dispatch(patchExperience(submitValues));
        }
        else {
          dispatch(postExperience(submitValues));
        }
      }

      handleClose();
    }

  })

  const handleClose = () => {
    if (props.setIsExperienceFormVisible) {
      props.setIsExperienceFormVisible(false)
    }
    else {
      setOpen(false);
    }
    if (props.setExperienceFormValues) {
      props.setExperienceFormValues(initialValues);
    }
    formik.setValues(initialValues);
  };
  return(
    <div className={styles.ExperienceForm}>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <form  onSubmit={formik.handleSubmit} className="container default-form">

          <DialogTitle>Experience</DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="form-group col-lg-12 col-md-12">
                <label>Company</label>
                <TextField
                  fullWidth
                  name="company"
                  variant="standard"
                  value={formik.values.company ? formik.values.company : ''}
                  onChange={formik.handleChange}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </div>

              <div className="form-group col-lg-12 col-md-12">
                <label>Role</label>
                <TextField
                  fullWidth
                  name="role"
                  variant="standard"
                  value={formik.values.role ? formik.values.role : ''}
                  onChange={formik.handleChange}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </div>

              <div className="form-group col-lg-12 col-md-12">
                <label>Description</label>
                <TextField
                  fullWidth
                  multiline
                  name="description"
                  variant="standard"
                  value={formik.values?.description ? formik.values?.description : ''}
                  onChange={formik.handleChange}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </div>
              
              <div className="form-group col-lg-6 col-md-6">
                <label>From Date</label>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={formik.values.from_date ? formik.values.from_date : null}
                        onChange={(newValue) => formik.setFieldValue('from_date', newValue)}
                      />
                  </LocalizationProvider>
                </div>
              </div>
              
              <div className="form-group col-lg-6 col-md-6">
                <label>To Date</label>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={formik.values.to_date ? formik.values.to_date : null}
                      onChange={(newValue) => {
                        return formik.setFieldValue('to_date', newValue)
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>

            <div className="form-group col-lg-12 col-md-12">
              <FormControlLabel control={<Checkbox
                name="current_job"
                checked={formik.values.current_job ? true : false}
                onChange={(e, isChecked) => formik.setFieldValue('current_job', isChecked)}
              />} label="On going" />
            </div>
            <div className="form-group col-lg-12 col-md-12">
              <label>Industry</label>
              <Select
                fullWidth
                name="industry"
                value={formik.values.industry ? formik.values.industry.id  : ''}
                onChange={(e, value) => {
                    formik.setFieldValue('industry', {
                      id: e.target.value,
                      name: ''
                    })
                    formik.setFieldValue("industry_id", e.target.value)
                  }
                }
                error={formik.touched.industry && Boolean(formik.errors.industry)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {taxonomyIndustry.map((term) => (
                    <MenuItem key={term.id} value={term.id}>{term.name}</MenuItem>
                  )
                )}
              </Select>
            </div>
            <div className=" col-lg-12 col-md-12">
              <label>Skills</label>
              <SkillAutocomplete
                value={formik.values.skills ? formik.values.skills : []}
                multiple={true}
                disableCloseOnSelect={true}
                onChange={(values: ISkill[]) => {
                  formik.setFieldValue("skill_id", values?.map((value)=>value.id))
                  formik.setFieldValue("skills", values)
                }}
              ></SkillAutocomplete>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-lg-12 col-md-12 mt-2 mb-4 d-flex justify-content-end">
              <button onClick={handleClose} className="theme-btn btn-style-eight narrow ">Cancel</button>
              <button onClick={() => {formik.handleSubmit()}} type="submit" className="theme-btn btn-style-one narrow ms-2">Save</button>
            </div>
          </div>
        </DialogContent>
        </form>
      </Dialog>
    </div>
  )
};

export default ExperienceForm;
