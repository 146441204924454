import React, { FC, useEffect, useState } from 'react';
import styles from './login-with-social.module.scss';
import { useGoogleLogin } from "@react-oauth/google";
import { useLinkedIn } from 'react-linkedin-login-oauth2';

import { googleSocialSuccess, linkedinAccessToken } from './logic/api-social-login';

export interface LoginWithSocialProps {}

const LoginWithSocial: FC<LoginWithSocialProps> = (props) => {
  const [linkedinAuthorizationCode, setLinkedinAuthorizationCode] = useState('');
  
  useEffect(() => {
    if (linkedinAuthorizationCode) {
      linkedinAccessToken(linkedinAuthorizationCode)
    }
  }, [linkedinAuthorizationCode])
  
  const { linkedInLogin } = useLinkedIn({
    clientId: '77d4rh7rdjofo4',
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      setLinkedinAuthorizationCode(code)
    },
    scope:'r_liteprofile,r_emailaddress',
    onError: (error) => {
      // console.log(error);
    },
  });

  const loginGoogle = useGoogleLogin({
    onSuccess: codeResponse => {
      googleSocialSuccess(codeResponse.code)
    },
    flow: 'auth-code',
  });
    
  return (
    <div className="btn-box row">
      <div className="col-lg-6 col-md-12">
        <button onClick={() => linkedInLogin()} className="theme-btn social-btn-two linkedin-btn">
          <i className="fab fa-linkedin"></i> Log In via Linkedin
        </button>
      </div>
      <div className="col-lg-6 col-md-12">
        <a onClick={() => loginGoogle()} className="theme-btn social-btn-two google-btn">
          <i className="fab fa-google"></i> Log In via Gmail
        </a>
      </div>
    </div>
  );
}

export default LoginWithSocial;

