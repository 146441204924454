import React, { FC, useEffect } from 'react';
import styles from './details.module.scss';
import { Grid } from '@mui/material';
import ButtonJobApply from '../../../components/button-job-apply/button-job-apply';
import { ITerm } from '../../candidate/logic/models';
import { IJobDetails } from './logic/models';
import { CallToAction } from '@mui/icons-material';
import JobHead from '../components/job-head/job-head';
import JobOverview from '../components/job-overview/job-overview';
import JobTags from '../../../components/job-tags/job-tags';

export interface JobDetailsProps {
  details: IJobDetails
}

const JobDetails: FC<JobDetailsProps> = (props) => {
  const { details } = props; 
  const {
    id,
    title,
    company,
    company_name,
    description,
    job_types,
    position_levels,
    skills,
    benefits,
    industries,
  } = props.details;

  return (
    <>
      {/* <!-- Job Detail Section --> */}
      <section className="job-detail-section">
        <div className="job-detail-outer">
          <div className="auto-container">
              <div className="row mb-5">
                <div className="content-column col-lg-8 col-md-12 col-sm-12">
                  <div className="job-block-seven">
                    <div className="inner-box">
                      <JobHead details={details}>
                        <JobTags details={details}/>
                      </JobHead>
                    </div>
                  </div>
                </div>

                <div className="sidebar-column col-lg-4 col-md-12 col-sm-12">
                  <div className="job-block-seven d-flex justify-content-end md-justify-content-center">
                    <div className="inner-box">
                      <div className="btn-box">
                        <ButtonJobApply jobPostId={id} />
                      </div>
                    </div>
                  </div>
                  {/* <!-- Apply Job Block --> */}
                </div>
                
              </div>
            <div className="row md-flex-column-reverse">
              <div className="content-column col-lg-8 col-md-12 col-sm-12">
                <div className="job-detail job-description-reset">
                  <div dangerouslySetInnerHTML={{__html: description}}></div>
                </div>
                {/* End jobdetails content */}
              </div>
              {/* End .content-column */}

              <div className="sidebar-column col-lg-4 col-md-12 col-sm-12">
                <aside className="sidebar">

                    <JobOverview details={details} />

                </aside>
                {/* End .sidebar */}
              </div>
              {/* End .sidebar-column */}
            </div>
          </div>
        </div>
        {/* <!-- job-detail-outer--> */}
      </section>
      {/* <!-- End Job Detail Section --> */}
    </>
  );
};

export default JobDetails;



// {jobDetails?.id}
// {jobDetails?.title}
// {jobDetails?.job_types.map((item: ITerm) => (<div key={item.id}>{item.name}</div>))}
// {jobDetails?.position_levels.map((item: ITerm) => (<div key={item.id}>{item.name}</div>))}
// {jobDetails?.industries?.map((item: ITerm) => (<li key={item.id}>{item.name}</li>))}
// {jobDetails?.benefits?.map((benefit: ITerm) => (<li key={benefit.id}>{benefit.name}</li>))}