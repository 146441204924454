import React, { FC } from 'react';
import styles from './file-details.module.scss';
import { IFile } from '../../logic/models';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { deleteFile } from '../../logic/candidateSlice';

export interface FileDetailsProps {
  details: IFile
}

const FileDetails: FC<FileDetailsProps> = (props: FileDetailsProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(deleteFile({id:props.details.id}))
  }
  const printExtension = (fileName:string) => {
    var ext = fileName.split('.').pop()
    return ext?.toUpperCase();
  }
  return (
    <>
      <td>
        <a href={props.details.file} target='_blank' rel="noreferrer">{props.details.file_name}</a>
      </td>
      <td>{printExtension(props.details.file_name)}</td>

      <td>
        <div className="option-box">
          <ul className="option-list">
            <li>
              <button data-text="Delete" onClick={handleClickOpen}>
                <span className="la la-trash"></span>
              </button>
            </li>
          </ul>
        </div>
      </td>

      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm file removal?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="container">
              <div className="row">
                  <div className="form-group col-lg-12 col-md-12 mt-2 mb-4 d-flex justify-content-end">
                    <button onClick={handleClose} className="theme-btn btn-style-eight narrow ">Cancel</button>
                    <button  onClick={handleDelete}  type="submit" className="theme-btn btn-style-one narrow ms-2">Delete</button>
                  </div>
                </div>
              </div>
          </DialogActions>
      </Dialog>
      </>

  )
}
export default FileDetails;
