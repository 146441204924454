import React, { FC } from 'react';
import styles from './mobile-menu.module.scss';
import MobileSidebar from '../mobile-sidebar';
import { Link } from 'react-router-dom';
import logo from '../../../../../assets/images/logo.svg'
import HeaderUser from '../header-user/header-user';

interface MobileMenuProps {}

const MobileMenu: FC<MobileMenuProps> = () => {
  return (
    // <!-- Main Header-->
    <header className="main-header main-header-mobile">
      <div className="auto-container">
        {/* <!-- Main box --> */}
        <div className="inner-box">
          <div className="nav-outer">
            <div className="logo-box">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="brand" />
                </Link>
              </div>
            </div>
            {/* End .logo-box */}

            <MobileSidebar />
            {/* <!-- Main Menu End--> */}
          </div>
          {/* End .nav-outer */}

          
          <div className="outer-box">
            <div className="login-box">
              <HeaderUser/>
            </div>
            {/* login popup end */}

            <a
              href="#"
              className="mobile-nav-toggler"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasMenu"
            >
              <span className="flaticon-menu-1"></span>
            </a>
            {/* right humberger menu */}
          </div>
        </div>
      </div>
    </header>
  );
}
export default MobileMenu;
