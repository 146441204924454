import { Dayjs } from "dayjs";
import { ICompany } from "../../../pages/home/logic/top-company-data";
import { IJobLocation } from "../../job/details/logic/models";

export interface IUser {
    id: number;
    email: string;
    first_name?: string;
    last_name?: string;
    candidate?: ICandidate
}

export interface ICandidate {
    id: number;
    cv_file: string;
    files: IFile[];
    age: ITaxonomyItem;
    description: string;
    availability: ITaxonomyItem;
    pronounce: ITaxonomyItem;
    skill: ICandidateSkill[];
    experience: ICandidateExperience[];
    education: ICandidateEducation[];
    language: ICandidateLanguage[];
    industry: IIndustry[];
    country: ICountry;
    city: ICity;
    phone: string;
    allow_application_notification: boolean;
    social_facebook: string,
    social_twitter: string,
    social_github: string,
    social_linkedin: string,
}

export interface IFile {
    id: number;
    file: string;
    file_name: string;
}

export interface ICandidateSkill {
    id?: number;
    proficiency?: ITaxonomyItem;
    proficiency_id?: number;
    skill: ISkill;
    skill_id: number;
}

export interface ICandidateExperience {
    id?: number;
    company?: string;
    from_date?: string | Dayjs | null;
    to_date?: string | Dayjs | null;
    current_job?: boolean;
    description?: string;
    role?: string;
    size?: string;
    skills?: ISkill[];
    skill_id?: number[];
    industry?: IIndustry;
    industry_id?: number | null;
}

export interface ICandidateEducation {
    id?: number;
    organization?: string;
    from_date_month?: number;
    from_date_year?: number;
    to_date_month?: number;
    to_date_year?: number;
    degree_other?: string;
    current_education?: boolean;
    degree?: ITerm;
    degree_id?: number;
    study_field?: string;
    skills?: ISkill[]
    skill_id? : number[]
}

export interface ICandidateLanguage {
    id?: number;
    language?: ILanguage;
    language_id?: number;
    read?: ITerm;
    read_id?: number;
    write?: ITerm;
    write_id?: number;
    speak?: ITerm;
    speak_id?: number;
}

export interface ITerm {
    id: number;
    name: string;
    category?: string;
}

export interface IIndustry {
    id: number;
    name: string;
    job_number?: number;
    css_class_name?: string;
}
export interface ILanguage {
    id: number;
    name: string;
}

export interface ISkill {
    id: number,
    name: string
}

export interface ITaxonomyItem {
    id: number;
    name: string;
}


export interface IContinent {
    id: number;
    name: string;
}
export interface ICountry {
    id: number;
    name: string;
    phone_code: number;
}

export interface IState {
    id: number,
    name: string
}
export interface ICity {
    id: number;
    name: string;
    country: ICountry;
}


export interface ITaxonomies {
    ages: ITaxonomyItem[];
    degrees: ITaxonomyItem[];
    availability: ITaxonomyItem[];
    pronounce: ITaxonomyItem[];
    proficiency: ITaxonomyItem[];
    language_proficiency: ITaxonomyItem[];
    languages: ITaxonomyItem[];
    industries: ITaxonomyItem[]
}

export interface IJobPostShort {
    id: number;
    title: string;
    company: ICompany;
    locations: IJobLocation[]
}
export interface IJobApplication {
    id: number;
    job_post: IJobPostShort;
    status: ITerm;
    created_at: string;
}

export enum FileUploadModes {
    CV = 1,
    FILE = 2,
}