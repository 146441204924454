import React, { FC, useEffect } from 'react';
import styles from './user-industries-form.module.scss';
import { Autocomplete, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectTaxonomyIndustries, selectUserProfile, getCountries, getProfileDetails, getTaxonomies, patchCandidate, patchIndustries } from '../../logic/candidateSlice';
import { ITerm, ITaxonomyItem } from '../../logic/models';
import * as yup from 'yup';
import { AppDispatch } from '../../../../app/store';

interface UserIndustriesFormProps {}

const validationSchema = yup.object({
  industries: yup
    .array()
    .required(),
});
interface ICandidateValues {
  industries: ITerm[] | null | undefined;
}

const UserIndustriesForm: FC<UserIndustriesFormProps> = () =>{
  const taxonomyIndustries = useSelector(selectTaxonomyIndustries);  
  const user = useSelector(selectUserProfile);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCountries())
    dispatch(getProfileDetails())
    dispatch(getTaxonomies())
  }, []);
  const initialValues:ICandidateValues = {
    industries: []
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values) {
        dispatch(patchIndustries({
          industry: values.industries ? values.industries.map(item => ({'id':item.id})) : []
        }));
      }
    }
  })
  useEffect(() => {
    const userValues: ICandidateValues = {
      industries: user?.candidate?.industry
    }
    formik.setValues(userValues);
  }, [user])

  return (
    <form  onSubmit={formik.handleSubmit}>

          <div className="form-group col-xs-12">
            <Autocomplete
                multiple
                fullWidth
                disableCloseOnSelect
                id="industry-form-virtualize-industries"
                sx={{ marginBottom: '20px' }}
                disableListWrap
                options={taxonomyIndustries}
                getOptionLabel = {(option: ITaxonomyItem) => {
                  return option.name
                }}
                
                isOptionEqualToValue ={(option, value) => {
                  return option.id === value.id
                }}

                value={formik.values?.industries ? formik.values.industries : []}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    // value={formik.values.skill_id ? formik.values.skill_id : ''}
                    // error={formik.touched.skill_id && Boolean(formik.errors.skill_id)}
                  />
                )}
                renderOption={(props, option: ITaxonomyItem) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
                onChange={(e, values) => {
                    formik.setFieldValue("industries", values)
                  }
                }
              />
          </div>
           <button className="theme-btn btn-style-one mb-3" type="submit" onClick={() => {formik.handleSubmit()}}>Save</button>
           </form>
  )
}

export default UserIndustriesForm;
