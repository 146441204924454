import React, { FC, useEffect, useState } from 'react';
import styles from './resume-status.module.scss';
import { getProfileDetails, selectUserProfile } from '../../logic/candidateSlice';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../app/store'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { fontSize } from '@mui/system';


interface ResumeStatusProps {}

interface IResumeStatusState {
  city: boolean;
  country: boolean;
  pronounce: boolean;
  availability: boolean;
  skills: boolean;
  languages: boolean;
  education: boolean;
  experience: boolean;
  percent?: number;
}
const ResumeStatus: FC<ResumeStatusProps> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const initialValues: IResumeStatusState = {
    city: false,
    country: false,
    pronounce: false,
    availability: false,
    skills: false,
    languages: false,
    education: false,
    experience: false,
    percent: 0,
  }
  const user = useSelector(selectUserProfile);
  const [status, setStatus] = useState(initialValues);
  const [notCompletedItems, setNotCompletedItems] = useState<JSX.Element[]>([])
  useEffect(() => {
    dispatch(getProfileDetails())
  }, [])

  useEffect(() => {
    if (user) {
      const statusValues:IResumeStatusState = {
        city: user.candidate?.city ? true : false,
        country: user.candidate?.country ? true : false,
        pronounce: user.candidate?.pronounce ? true : false,
        availability: user.candidate?.availability ? true : false,
        skills: user.candidate?.skill && user.candidate?.skill?.length > 0 ? true : false,
        languages: user.candidate?.language && user.candidate?.language?.length > 0 ? true : false,
        education: user.candidate?.education && user.candidate?.education?.length > 0 ? true : false,
        experience: user.candidate?.experience && user.candidate?.experience?.length > 0 ? true : false,
      }
      const countTotal = Object.keys(statusValues).length;
      let countCompleted = 0;

      for (let value of Object.values(statusValues)) {
        if (value) {
          countCompleted++
        }
      }
      let notCompletedItems = Object.entries(statusValues).map(([key, value]) => {
        if (!value) {
          let path = '/user/resume';
          if ([
            'city',
            'country',
            'pronounce',
            'availability'
          ].indexOf(key) > -1) {
            path = '/user/profile'
          }

          return <div className="reel1" key={key}>
            <Link to={path} className={styles.label}>Add {key}</Link>
          </div>
        }

        return <></>;
      });
      let nonNullNotCompletedItems = notCompletedItems.filter(element => element.type !== React.Fragment);

      setNotCompletedItems(nonNullNotCompletedItems);
      statusValues.percent = Math.ceil(countCompleted/countTotal*100);
      setStatus(statusValues)
    }
    else {
      setStatus(initialValues);
    }
  }, [user]);

  const navigate = useNavigate();

  const handleOnEditProfileClick = () => {
    navigate('/resume');
  }
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const nextItem = () => {
    const prevIndex = currentIndex;
    let newIndex = prevIndex + 1;
    
    if (newIndex > (notCompletedItems.length - 1)) {
      newIndex = 0;
    }
    
    setCurrentIndex(newIndex);
  };
  useEffect(() => {
    const interval = setInterval(nextItem, 5000); // Change item every 3 seconds (3000 milliseconds)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [currentIndex, notCompletedItems]); // Empty dependency array ensures the effect runs once after the initial render


  return (
    <div className={ styles.ResumeStatus + " skills-percentage"}>
      <h4>Profile completion</h4>
      <div className={styles.ResumeStatusItemWrapper}>
      {notCompletedItems.filter((element, key) => {
        
        return key === currentIndex
      })}
      {/* currentIndex */}

      </div>
      <div style={{ width: 200, height: 200, margin: "auto" }}>
        { status.percent != null ? 
          <CircularProgressbar
            background
            backgroundPadding={6}
            styles={buildStyles({
              backgroundColor: "#7367F0",
              textColor: "#fff",
              pathColor: "#fff",
              trailColor: "transparent",
            })}
            value={status.percent}
            text={`${status.percent}%`}
          />
        : ''}
      </div>{" "}
      {/* <!-- Pie Graph --> */}
    </div>
  );
}

export default ResumeStatus;
