export const footerData = [
    {
      id: 1,
      title: "For Candidates",
      menuList: [
        { name: "Browse Jobs", route: "/search" },
        { name: "Candidate Dashboard", route: "/user/profile" },
        { name: "Applied Jobs", route: "/user/applied-jobs" },
        {
          name: "My Resume",
          route: "/user/resume",
        },
      ],
    },
    {
      id: 3,
      title: "About Us",
      menuList: [
        { name: "About Us", route: "/about-us" },
        { name: "Terms of Use", route: "/" },
        { name: "Privacy Center", route: "/" },
      ],
    },
  ];
  