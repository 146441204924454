import React, { FC, useEffect, useState } from 'react';
import styles from './user-form.module.scss';
import { Dialog, DialogTitle, DialogContent, Autocomplete, TextField, DialogActions, Button, FormControl, InputLabel, MenuItem, Select, InputAdornment, FormControlLabel, Checkbox } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import { getCountries, getProfileDetails, getTaxonomies, patchCandidate, patchIndustries, selectCandidateProfile, selectCountries, selectTaxonomyAge, selectTaxonomyIndustries, selectTaxonomyPronounce, selectTaxonomyWorkAvailability, selectUserProfile } from '../../logic/candidateSlice';
import { ICandidate, ICity, ICountry, ITaxonomyItem, ITerm, IUser } from '../../logic/models';
import * as yup from 'yup';
import CityAutocomplete from '../../../../components/city-autocomplete/city-autocomplete';

export interface UserFormProps {
  isUserFormVisible?: boolean,
  setIsUserFormVisible?: Function
}

const validationSchema = yup.object({
  first_name: yup
    .string()
    .required(),
  last_name: yup
    .string()
    .required(),
  age_id: yup.number().nullable(),
  availability_id: yup.number().nullable(),
});
interface ICandidateValues {
    id? : number;
    first_name?: string;
    last_name?: string;
    availability_id?: number | null;
    pronounce_id?: number | null;
    age_id?: number | null;
    age?: ITerm | null;
    allow_application_notification?: boolean | null
  }

const UserForm: FC<UserFormProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  
  const taxonomyWorkAvailability = useSelector(selectTaxonomyWorkAvailability);
  const taxonomyPronounce = useSelector(selectTaxonomyPronounce);
  const taxonomyAge = useSelector(selectTaxonomyAge);

  const user = useSelector(selectUserProfile);
  
  useEffect(() => {
    dispatch(getCountries())
    dispatch(getProfileDetails())
    dispatch(getTaxonomies())
  }, []);

  const initialValues:ICandidateValues = {
    first_name: '',
    last_name: '',
    availability_id: null,
    pronounce_id: null,
    age_id: null,
    allow_application_notification: false
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values) {
        const sendValues = values;
        dispatch(patchCandidate(sendValues));
      }
    }
  })

  useEffect(() => {
    const userValues: ICandidateValues = {
      first_name: user?.first_name,
      last_name: user?.last_name,
      age_id: user?.candidate?.age?.id,
      availability_id: user?.candidate?.availability?.id,
      pronounce_id: user?.candidate?.pronounce?.id,
      age: user?.candidate?.age,
      allow_application_notification: user?.candidate?.allow_application_notification
    }
    formik.setValues(userValues);
  }, [user])

   return (
      <div className={styles.UserForm}>
       <form  onSubmit={formik.handleSubmit} className="default-form">
          <div className="row">
            {/* <!-- Input first name --> */}
            <div className="form-group col-lg-6 col-md-12">
              <label>First Name</label>
                <TextField
                  id="first-name-text"
                  name="first_name"
                  fullWidth
                  variant="standard"
                  value={formik.values.first_name ? formik.values.first_name : ''}
                  onChange={formik.handleChange}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
            </div>
            
            {/* <!-- Input last name --> */}
            <div className="form-group col-lg-6 col-md-12">
              <label>Last Name</label>
              <TextField
                fullWidth
                id="last-name-text"
                name="last_name"
                variant="standard"
                value={formik.values.last_name ? formik.values.last_name : ''}
                onChange={formik.handleChange}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>

            {/* Pronounce */}
            <div className="form-group col-lg-6 col-md-12">
              <label>Pronounce</label>
              <Select
                labelId="pronounce-select-label"
                fullWidth
                name="pronounce"
                value={formik.values.pronounce_id !=null ? formik.values.pronounce_id : ''}
                onChange={(e, value) => {
                    const pronounceIdValue = e.target.value ? e.target.value : null;

                    formik.setFieldValue("pronounce_id", pronounceIdValue)
                  }
                }
                error={formik.touched.pronounce_id && Boolean(formik.errors.pronounce_id)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {taxonomyPronounce.map((item, idx) => (
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  )
                )}
              </Select>
            </div>

            {/* Age */}
            <div className="form-group col-lg-6 col-md-12">
              <label>Age</label>
              <Select
                labelId="age-select-label"
                fullWidth
                name="age"
                value={formik.values.age_id !=null ? formik.values.age_id : ''}
                onChange={(e, value) => {
                    const ageIdValue = e.target.value ? e.target.value : null;

                    formik.setFieldValue("age_id", ageIdValue)
                  }
                }
                error={formik.touched.age_id && Boolean(formik.errors.age_id)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {taxonomyAge.map((item, idx) => (
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  )
                )}
              </Select>
            </div>

            {/* Work availability */}
            <div className="form-group col-lg-6 col-md-12">
              <label>Work Availability</label>

              <Select
                fullWidth
                name="availability"
                value={formik.values.availability_id !=null ? formik.values.availability_id : ""}
                onChange={(e, value) => {
                    formik.setFieldValue("availability_id", e.target.value)
                  }
                }
                error={formik.touched.availability_id && Boolean(formik.errors.availability_id)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {taxonomyWorkAvailability.map((availabilityItem, idx) => (
                    <MenuItem key={availabilityItem.id} value={availabilityItem.id}>{availabilityItem.name}</MenuItem>
                  )
                )}
              </Select>
            </div>

            <div className="form-group col-md-12">
              <FormControl fullWidth variant="standard" sx={{ minWidth: 220 }} size="small">
                <FormControlLabel control={<Checkbox
                  name="allow_application_notification"
                  checked={formik.values.allow_application_notification ? true : false}
                  onChange={(e, isChecked) => formik.setFieldValue('allow_application_notification', isChecked)}
                />} label="Allow application notification" />
              </FormControl>
            </div>
          </div>

          <button className="theme-btn btn-style-one mb-3" type="submit" onClick={() => {formik.handleSubmit()}}>Save</button>
       </form>
      </div>
   );
}

export default UserForm;
