import React, { FC } from 'react';
import styles from './header-user.module.scss';
import HeaderLoginSection from '../header-login-section/header-login-section';
import UserDropdown from '../user-dropdown/user-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../app/store';
import { selectUserProfile } from '../../../../../features/candidate/logic/candidateSlice';

interface HeaderUserProps {}

const HeaderUser: FC<HeaderUserProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userProfile = useSelector(selectUserProfile);

  return (
    <div className={styles.HeaderUser}>
      { userProfile ? 
          <UserDropdown></UserDropdown>
        : <HeaderLoginSection></HeaderLoginSection>
      }
    </div>
  );
}

export default HeaderUser;
