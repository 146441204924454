import React, { FC } from 'react';
import styles from './header-login-section.module.scss';

interface HeaderLoginSectionProps {}

const HeaderLoginSection: FC<HeaderLoginSectionProps> = () => (
  <div className="outer-box">
    {/* <!-- Login/Register --> */}
    <div className="btn-box">
      <a
        href="/login"
        className="theme-btn btn-style-one"
      >
        Login / Register
      </a>
    </div>
  </div>
);

export default HeaderLoginSection;
