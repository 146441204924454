import React, { FC, useEffect, useState } from 'react';
import styles from './button-job-apply.module.scss';
import { Alert, Button, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getApplications, postApplyJob, resetApplicationCreatedState, selectCandidateProfile } from '../../features/candidate/logic/candidateSlice';
import { AppDispatch } from '../../app/store';

export interface ButtonJobApplyProps {
  jobPostId: number;
}

const ButtonJobApply: FC<ButtonJobApplyProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const isLoggedIn = localStorage.getItem('access_token');
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [hasUserApplied, setHasUserApplied] = useState(false)
  const {resetApplicationCreated, applications} = useSelector(selectCandidateProfile)
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getApplications());
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (resetApplicationCreated) {
      setOpen(true);
      dispatch(getApplications());
      dispatch(resetApplicationCreatedState(null))
    }
  }, [resetApplicationCreated, dispatch])

  useEffect(() => {
    let isButtonVisible = false;
    let hasUserApplied = false;
    if (isLoggedIn) {
      isButtonVisible = true;
      if (applications) {
        for (let applicationItem of applications) {
          if (applicationItem.job_post.id === props.jobPostId) {
            hasUserApplied = true;
            break;
          }
        }
      }
    }
    setIsButtonVisible(isButtonVisible);
    setHasUserApplied(hasUserApplied);
  }, [isLoggedIn, applications, props.jobPostId]) 

  const handleClick = () => {
    dispatch(postApplyJob({job_post_id: props.jobPostId}))
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <div className={styles.ButtonJobApply}>
      { isLoggedIn ?
        (
          hasUserApplied ? 
          <Button color="success">Applied</Button>
          :
          <a 
            className="theme-btn btn-style-one"
           onClick={handleClick}> Apply For Job</a>
        )
        : ''
      }
      
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Success!
        </Alert>
      </Snackbar>
    </div>
  )
};

export default ButtonJobApply;
