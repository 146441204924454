import React, { FC } from 'react';
import styles from './experience-details.module.scss';
import { ICandidateExperience } from '../../logic/models';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { deleteExperience } from '../../logic/candidateSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import dayjs from 'dayjs';
import { formatDate } from '../../../../app/helper';
interface ExperienceDetailsProps {
  details: ICandidateExperience,
  setExperienceFormValues?: Function

}

const ExperienceDetails: FC<ExperienceDetailsProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleEdit = () => {
    if (props.setExperienceFormValues) {
      const values: ICandidateExperience = {
        ...props.details,
        from_date: props.details.from_date? dayjs(props.details.from_date) : null,
        to_date: props.details.to_date ? dayjs(props.details.to_date) : null,
        skill_id: props.details.skills?.map((skill) => skill.id)
      }

      props.setExperienceFormValues(values);
    }
  }

  const handleDelete = () => {
    dispatch(deleteExperience({id:props.details.id}))
  }
  return (
    <>
      <div className="resume-block">
        <div className="inner">
          <span className="name">{props.details.role ? props.details.role[0] : '' }</span>
          <div className="title-box">
            <div className="info-box">
              <h3>{props.details.role}</h3>
              <span>{props.details.company}</span>
            </div>
            <div className="edit-box">
              <span className="year">{formatDate(props.details.from_date)} - {props.details.current_job ? 'Current' : formatDate(props.details.to_date)}</span>
              <div className="edit-btns">
                <button onClick={handleEdit}>
                  <span className="la la-pencil"></span>
                </button>
                <button onClick={handleClickOpen}>
                  <span className="la la-trash"></span>
                </button>
              </div>
            </div>
          </div>
          <div className="text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a
            ipsum tellus. Interdum et malesuada fames ac ante
            <br /> ipsum primis in faucibus.
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Experience removal?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="container">
            <div className="row">
                <div className="form-group col-lg-12 col-md-12 mt-2 mb-4 d-flex justify-content-end">
                  <button onClick={handleClose} className="theme-btn btn-style-eight narrow ">Cancel</button>
                  <button  onClick={handleDelete}  type="submit" className="theme-btn btn-style-one narrow ms-2">Delete</button>
                </div>
              </div>
            </div>
        </DialogActions>
      </Dialog>
    </>
  );
}


export default ExperienceDetails;
