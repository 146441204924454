import React, { FC, useEffect } from 'react';
import styles from './home.module.scss';
import Hero from '../../components/hero/hero';
import MobileMenu from '../../components/layout/header/components/mobile-menu/mobile-menu';
import { dataHowItWorks } from './logic/data';
import BlockCategories from '../../components/block-categories/block-categories.lazy';
import Header from '../../components/layout/header/header/header';
import Footer from '../../components/layout/footer/footer/footer';
import BlockInfoUploadCvStories from '../../components/block-info-upload-cv/block-info-upload-cv.lazy';
import BlockIconTextItems from '../../components/block-icon-text-items/block-icon-text-items';
import JobListBlock from '../../components/job-list-block/job-list-block';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { getFeaturedIndustries, getFeaturedJobPosts, getPopularSearch, selectHomeState } from './logic/homeSlice';

interface HomeProps {}

const Home = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {
        jobCount,
        jobCountToday,
        featuredIndustries,
        featuredJobPosts,
        popularSearch
    } = useSelector(selectHomeState);

    const dataHowItWorksLocal = dataHowItWorks;
    useEffect(() => {
        dispatch(getFeaturedIndustries());
        dispatch(getFeaturedJobPosts());
        dispatch(getPopularSearch());
      }, [])
    
    return (
        <>
            <Header />
            {/* <!--End Main Header --> */}

            <MobileMenu />
            {/* End MobileMenu */}

            <Hero popularSearch={popularSearch}/>
            {/* <!-- End Banner Section--> */}

            <section className="job-categories">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <h2>Popular Industries</h2>
                        {   jobCount && jobCountToday ?
                            <div className="text">
                            {jobCount} jobs live - {jobCountToday} added today.
                        </div>
                        : ''}
                    </div>

                    <div
                        className="row "
                        data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom"
                    >
                        {/* <!-- Category Block --> */}
                        <BlockCategories content={featuredIndustries}></BlockCategories>
                    </div>
                </div>
            </section>
            {/* End Job Categorie Section */}

            <JobListBlock title="Featured Jobs" subtitle="Know your worth and find the job that qualify your life" jobs={featuredJobPosts}/>
            {/* <!-- End Job Section --> */}
            
            <section className="work-section">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <h2>How It Works?</h2>
                        <div className="text">Job for anyone, anywhere</div>
                    </div>

                    <div className="row" data-aos="fade-up">
                        <BlockIconTextItems blockContent={dataHowItWorksLocal}></BlockIconTextItems>
                    </div>
                </div>
            </section>
            {/* <!-- End Process Section --> */}

            {/* Upload CV block info */}
            <BlockInfoUploadCvStories />
            {/* END Upload CV block info */}
            <Footer footerStyle='alternate' />

            {/* <!-- End Main Footer --> */}
        </>
    );
};

export default Home;
