import React, { FC, useEffect, useState } from 'react';
import styles from './login-form.module.scss';
import { Button, Container, Grid, TextField } from '@mui/material';
import api from '../../../app/api';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import axios from 'axios';
import { CredentialResponse, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import LoginWithSocial from '../../../components/auth/login-with-social/login-with-social';


interface LoginFormProps {}

const LoginForm: FC<LoginFormProps> = () => {
  const navigate = useNavigate();
  
  const initialFormData = Object.freeze({
    email: '',
    password: '',
  })

  const [message, setMessage] = useState('');
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if(searchParams && searchParams.get('verify')) {
      setMessage('User verified successfully. Please login')
    }
  }, [searchParams])

  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e: any) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    api.post(`auth/token`,{
      grant_type: 'password',
      client_id: '6NZTYZGz07adB6GRmSnCwm3MxbLIG3TQqVQcdubg',
      client_secret: 'jL4PCR9N9vsAblSj5hskmdYiSuY2sHw25wVZDup8Q31chkZIpN0QuR211bQVXOWSXbcQwyvESyiPTHP0CmW9hxq8A5fASTiP4WldB6GnArgTvkzK9RsjfY5LFym0JeBZ',
      username: formData.email,
      password: formData.password,
    }).then((response) => {
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      navigate('/');
      window.location.reload();
    }).catch((error) => {
      setMessage(error.response.data.error_description)
    })
  }
  
  return (

    <>
      <form noValidate>
        <div className={styles.LoginForm}>
          { message ? message  : ''}
          <br/>
          <label>Email Address</label>
          <TextField 
            id="email"
            placeholder="Email"
            variant="outlined" 
            type="email"
            name="email"
            fullWidth
            className={styles.TextField}
            onChange={handleChange}
          />
          <br/>
          <br/>
          <label>Password</label>
          <TextField 
            id="password"
            placeholder="Password"
            variant="outlined"
            type="password"
            name="password"
            fullWidth
            className={styles.TextField}
            onChange={handleChange}
          />
        </div>
        
        <div className="form-group">
          <div className="field-outer">
            <div className="input-group checkboxes square">
              <input type="checkbox" name="remember-me" id="remember" />
              <label htmlFor="remember" className="remember">
                <span className="custom-checkbox"></span> Remember me
              </label>
            </div>
            <a href="/send-reset-password-link" className="pwd">
              Forgot password?
            </a>
          </div>
        </div>
        {/* forgot password */}

        <div className="form-group">
          <button
            onClick={handleSubmit}
            className="theme-btn btn-style-one"
            type="submit"
            name="log-in"
          >
            Log In
          </button>
        </div>
      </form>
      <div className="bottom-box">
        <div className="text">
          Don&apos;t have an account?{" "}
          <Link
            to="/sign-up"
            className="call-modal signup"
          >
            Signup
          </Link>
        </div>

        <div className="divider">
          <span>or</span>
        </div>

        <LoginWithSocial />
      </div>

    </>
  )
};

export default LoginForm;
