import React, { FC, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import styles from './main.module.scss';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import VerifyUser from '../../../features/auth/verify-user/verify-user';
import UserLoggedInRoute from '../../../app/utils/UserLoggedInRoute';
import UserAnonymousRoute from '../../../app/utils/UserAnonymousRoute';
import Page from '../../../features/page/page/page';
import HomePage from '../../../pages/home/home';
import Job from '../../../pages/job/job';
import Login from '../../../pages/login/login';
import Registration from '../../../pages/registration/registration.lazy';
import Search from '../../../pages/search/search.lazy';
import ResetPasswordPage from '../../../pages/reset-password/reset-password-page';
import ResetPasswordLinkPage from '../../../pages/reset-password-link/reset-password-link-page.lazy';
import { getProfileDetails, selectUserTokens } from '../../../features/candidate/logic/candidateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../app/store';
import Resume from '../../../pages/user/resume/resume';
import AppliedJobs from '../../../pages/user/applied-jobs/applied-jobs';
import Profile from '../../../pages/user/profile/profile';
import AboutUs from '../../../pages/about-us/about-us';
import { selectGeneralIsLoading } from '../../../features/general/logic/generalSlice';
import ChangePassword from '../../../pages/change-password/change-password';


interface MainProps {}

const Main: FC<MainProps> = () => {
  // @todo redirect on user login 
  const dispatch = useDispatch<AppDispatch>();
  const tokens = useSelector(selectUserTokens);
  const isLoading = useSelector(selectGeneralIsLoading);

  useEffect(()=>{
    if (tokens && tokens.access) {
      dispatch(getProfileDetails())
    }
  }, [tokens])
  return (
    <div className={styles.Main}>
      { isLoading ? <div className='mask-loading'><i className="fa fa-circle-notch"></i></div> : ''}
      
      <Routes>
        <Route path="/linkedin" element={LinkedInCallback()} />
        <Route path='/search' element={<Search />}></Route>
        <Route path='/about-us' element={<AboutUs />}></Route>
        <Route path='/job/:id' element={<Job/>}></Route>
        <Route path='/page/:slug' element={<Page/>}></Route>
        <Route path='/' element={<HomePage/>}></Route>

        {/* <UserLoggedInRoute> */}
          <Route path='/user/profile' element={
            <UserLoggedInRoute>
              <Profile/>
            </UserLoggedInRoute>
          }></Route>
          <Route path='/user/resume' element={
            <UserLoggedInRoute>
              <Resume/>
            </UserLoggedInRoute>
          }></Route>

          <Route path='/user/applied-jobs' element={
            <UserLoggedInRoute>
              <AppliedJobs/>
            </UserLoggedInRoute>
          }></Route>
          <Route path='/user/change-password' element={
            <UserLoggedInRoute>
              <ChangePassword/>
            </UserLoggedInRoute>
          }></Route>
        {/* </UserLoggedInRoute> */}

        {/* <UserAnonymousRoute> */}
          <Route path='/login' element={
            <UserAnonymousRoute>
              <Login/>
            </UserAnonymousRoute>
          }></Route>
          <Route path='/sign-up' element={
            <UserAnonymousRoute>
              <Registration/>
            </UserAnonymousRoute>
          }></Route>

          <Route path='/reset-password' element={
            <UserAnonymousRoute>
              <ResetPasswordPage/>
            </UserAnonymousRoute>
          }></Route>

          <Route path='/send-reset-password-link' element={
            <UserAnonymousRoute>
              <ResetPasswordLinkPage />
            </UserAnonymousRoute>
          }></Route>
          <Route path='/verify-user' element={
            <UserAnonymousRoute>
              <>
                <HomePage/>
                <VerifyUser/>
              </>
            </UserAnonymousRoute>
          }></Route>
        {/* </UserAnonymousRoute> */}

      </Routes>
    </div>
  )
};

export default Main;
