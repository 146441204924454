import React, { FC, useEffect } from 'react';
import styles from './job-applications.module.scss';
import { getApplications, selectCandidateProfile } from '../logic/candidateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../app/store';
import { Link } from 'react-router-dom';
import { convert_locations_to_string_array } from '../../../app/helper';

interface JobApplicationsProps {}

const JobApplications: FC<JobApplicationsProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { applications } = useSelector(selectCandidateProfile);

  useEffect(() => {
    dispatch(getApplications());
  }, [])  

  function dateTimeFormatter(dateTimeString: string | number | Date) {
    const formattedDateTime = new Date(dateTimeString).toLocaleString();

    return <span>{formattedDateTime}</span>;
  }

  return (
    <div className="tabs-box">
      <div className="widget-title">
        <h4>My Applied Jobs</h4>
      </div>
      {/* End filter top bar */}
      <div className="widget-content">
        <div className="table-outer">
          <div className="table-outer">
            <table className="default-table manage-job-table">
              <thead>
                <tr>
                  <th>Job Title</th>
                  <th>Date Applied</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
                {applications.map((item) => (
                  <tr key={item.id}>
                    <td>
                      {/* <!-- Job Block --> */}
                      <div className="job-block">
                        <div className="inner-box">
                          <div className="content">
                            <h4>
                              <Link to={"/job/" + item.job_post.id} target="_blank" rel="noreferrer">
                                {item.job_post.title}
                              </Link>
                            </h4>
                            <ul className="job-info">
                              <li>
                                <span className="icon flaticon-briefcase"></span>
                                {item.job_post.company.name}
                              </li>
                              <li>
                                <span className="icon flaticon-map-locator"></span>
                                { convert_locations_to_string_array(item.job_post.locations)}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{dateTimeFormatter(item.created_at)}</td>
                    <td className="status">{item.status.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* End table widget content */}
    </div>




  );
}
export default JobApplications;
