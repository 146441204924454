import React, { FC } from 'react';
import styles from './popular-search.module.scss';

export interface IPopularSearch {
  id: number,
  name: string,
  search: string
}
export interface PopularSearchProps {
  items: IPopularSearch[]
}

const PopularSearch: FC<PopularSearchProps> = (props) => {
  const {items} = props;
  return (
    <>
      {items.length > 0 ? 
        <div className={styles.PopularSearch}>
          <div className="popular-searches" data-aos="fade-up" data-aos-delay="1000">
            <span className="title">Popular Searches: </span>
            {items.map((item, i) => {
              return (
                <span key={item.id}>
                  <a  href={item.search}>{item.name}</a>
                  { i < (items.length-1) ? ', ':''}
                </span>
              )
            })}
          </div>
        </div>
      
    : ''}
    </>
  );
}

export default PopularSearch;
