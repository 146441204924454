import React, { FC } from 'react';
import styles from './partners.module.scss';
import Slider from 'react-slick';
import client1 from '../../assets/images/clients/1-1.png';
import client2 from '../../assets/images/clients/1-2.png';
import client3 from '../../assets/images/clients/1-3.png';
import client4 from '../../assets/images/clients/1-4.png';
import client5 from '../../assets/images/clients/1-5.png';
import client6 from '../../assets/images/clients/1-6.png';
import client7 from '../../assets/images/clients/1-7.png';
interface PartnersProps {}

const Partners: FC<PartnersProps> = () => {
  const settings = {
    dots: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1200,

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderGallery = [
    { id: 1, link: "#", img: client1 },
    { id: 2, link: "#", img: client2 },
    { id: 3, link: "#", img: client3 },
    { id: 5, link: "#", img: client5 },
    { id: 6, link: "#", img: client6 },
    { id: 7, link: "#", img: client7 },
  ];

  return (
    <>
      <Slider {...settings} arrows={false}>
        {sliderGallery.map((item) => (
          <li className="slide-item" key={item.id}>
            <figure className="image-box">
              <a href={item.link}>
                <img src={item.img} alt="brand" />
              </a>
            </figure>
          </li>
        ))}
      </Slider>
    </>
  );
};

export default Partners;
