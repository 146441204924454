import React, { FC } from 'react';
import styles from './bread-crumb.module.scss';

export interface BreadCrumbProps {
  title: string
}

const BreadCrumb: FC<BreadCrumbProps> = (props) => (
  <div className="upper-title-box">
    <h3>{props.title}</h3>
    <div className="text">Ready to jump back in?</div>
  </div>
);

export default BreadCrumb;
