import React, { lazy, Suspense } from 'react';

const LazyResetPasswordLinkPage = lazy(() => import('./reset-password-link-page'));

const ResetPasswordLinkPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyResetPasswordLinkPage {...props} />
  </Suspense>
);

export default ResetPasswordLinkPage;
