import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPage, IPageListItem } from "./models";
import api from "../../../app/api";
import { RootState } from "../../../app/store";

export interface IPageState {
    list: IPageListItem[],
    pageDetails: IPage | null
}

const initialState: IPageState = {
    list: [],
    pageDetails: null
}

export const selectPageList = (state: RootState) => state.page.list;
export const selectPageDetails = (state: RootState) => state.page.pageDetails;

export const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getListPages.fulfilled, (state, action) => {
            state.list = action.payload;
        })
        .addCase(getPageDetails.fulfilled, (state, action) => {
            state.pageDetails = action.payload;
        })
    }
});

export const getListPages = createAsyncThunk(
    'page/list',
    async(payload, {rejectWithValue}) => {
        try {
            const response = await api.get('page/');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
);

export const getPageDetails = createAsyncThunk(
    'page/details',
    async(pageSlug: string, { rejectWithValue}) => {
        try {
            const response = await api.get('page/' + pageSlug);
            return response.data;
        }
        catch(error) {
            rejectWithValue('Error');
        }
    }
);

export default pageSlice.reducer;
