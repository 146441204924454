import React, { FC } from 'react';
import styles from './footer.module.scss';
import FooterContent from '../components/footer-content/footer-content';
import { footerData } from '../data';
import FooterCopyright from '../components/footer-copyright/footer-copyright';
import logo from '../../../../assets/images/logo.svg'


export interface FooterProps {
  footerStyle?: string
}

const Footer: FC<FooterProps> = (props) => {
  const { footerStyle } = props
  return (
    <footer className={`main-footer alternate5 ${footerStyle}`}>
      <div className="auto-container">
        {/* <!--Widgets Section--> */}
        <div className="widgets-section" data-aos="fade-up">
          <div className="row">
            <div className="big-column col-xl-4 col-lg-3 col-md-12">
              <div className="footer-column about-widget">
                <div className="logo">
                  <a href="#">
                    <img src={logo} alt="brand" />
                  </a>
                </div>
                <p className="phone-num">
                  <span>Call us </span>
                  <a href="thebeehost@support.com">123 456 7890</a>
                </p>
                <p className="address">
                  329 Queensberry Street, North Melbourne VIC
                  <br /> 3051, Australia. <br />
                  <a href="mailto:support@superio.com" className="email">
                    support@superio.com
                  </a>
                </p>
              </div>
            </div>
            {/* End footer left widget */}

            <div className="big-column col-xl-8 col-lg-9 col-md-12">
              <div className="row">
                <FooterContent content={footerData} />
              </div>
            </div>
            {/* End col-xl-8 */}
          </div>
        </div>
      </div>
      {/* End auto-container */}

      <FooterCopyright />
      {/* <!--Bottom--> */}
    </footer>
    //   {/* <!-- End Main Footer --> */}
  );
}
export default Footer;
