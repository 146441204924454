import React, { FC, useEffect, useState } from 'react';
import styles from './language-form.module.scss';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Checkbox, FormControlLabel, FormControl, InputLabel, MenuItem, Select, Autocomplete } from '@mui/material';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { patchLanguage, postLanguage, selectTaxonomyProficiency, selectTaxonomyLanguages, selectTaxonomyLanguageProficiency } from '../../logic/candidateSlice';
import { ICandidateLanguage, ITaxonomyItem } from '../../logic/models';
import { AppDispatch } from '../../../../app/store';
export interface LanguageFormProps {
  isLanguageFormVisible?: boolean, 
  setIsLanguageFormVisible?: Function,
  setLanguageFormValues?: Function, 
  editLanguage?: ICandidateLanguage
}

const validationSchema = yup.object({
  language_id: yup
    .number()
    .required(),
  read_id: yup
  .number()
  .required(),
  // write_id: yup
  // .number()
  // .required(),
  // speak_id: yup
  // .number()
  // .required()
});

const LanguageForm: FC<LanguageFormProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const taxonomyLanguages = useSelector(selectTaxonomyLanguages);  
  const taxonomyProficiency = useSelector(selectTaxonomyLanguageProficiency);

  useEffect(() => {
    if (props.editLanguage) {
      let values = {
        ...props.editLanguage
      };
      if (values.language && !values.language_id) {
        values.language_id = values.language.id;
      }
      
      formik.setValues(values);
    }
    else {
      formik.setValues(initialValues);
    }
  }, [props.editLanguage])
  
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let isOpen = false;
    if (props.isLanguageFormVisible != null)  {
      isOpen = props.isLanguageFormVisible;
    }
    setOpen(isOpen);

  }, [props.isLanguageFormVisible])
  const initialValues: ICandidateLanguage = {
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.id) {
        dispatch(patchLanguage(values));
      }
      else {
        dispatch(postLanguage(values));
      }
      handleClose();
    }

  })

  const handleClose = () => {
    if (props.setIsLanguageFormVisible) {
      props.setIsLanguageFormVisible(false)
    }
    else {
      setOpen(false);
    }
    if (props.setLanguageFormValues) {
      props.setLanguageFormValues(initialValues);
    }
    formik.setValues(initialValues);
  };
  return(
    <div className={styles.LanguageForm}>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <form  onSubmit={formik.handleSubmit}>
          <DialogTitle>Language</DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="form-group offset-lg-2 col-lg-8 col-md-12 mb-4">
              <Select
                  fullWidth
                  displayEmpty={true}
                  name="language"
                  value={formik.values.language ? formik.values.language.id  : ''}
                  onChange={(e, value) => {
                      formik.setFieldValue('language', {
                        id: e.target.value,
                        name: ''
                      })
                      formik.setFieldValue("language_id", e.target.value)
                    }
                  }
                  error={formik.touched.language && Boolean(formik.errors.language)}
                >
                  <MenuItem value="">
                    <span>Select Language</span>
                  </MenuItem>
                  {taxonomyLanguages.map((proficiency) => (
                      <MenuItem key={proficiency.id} value={proficiency.id}>{proficiency.name}</MenuItem>
                    ) 
                  )}
                </Select>
              </div>

            
              <div className="form-group offset-lg-2 col-lg-8 col-md-12 mb-4">
                <Select
                  fullWidth
                  name="read"
                  placeholder='test'
                  displayEmpty={true}
                  value={formik.values.read ? formik.values.read.id  : ''}
                  onChange={(e, value) => {
                      formik.setFieldValue("read", {
                        id: e.target.value,
                        name: '' // not used
                      })
                      formik.setFieldValue("read_id", e.target.value)
                    }
                  }
                  error={formik.touched.read && Boolean(formik.errors.read)}
                >
                  <MenuItem value="">
                    <span>Add Read Proficiency</span>
                  </MenuItem>
                  {taxonomyProficiency.map((proficiency) => (
                      <MenuItem key={proficiency.id} value={proficiency.id}>{proficiency.name}</MenuItem>
                    ) 
                  )}
                </Select>
              </div>


            <div className="form-group offset-lg-2 col-lg-8 col-md-12 mb-4">
                <Select
                  fullWidth
                  displayEmpty={true}
                  name="write"
                  value={formik.values.write ? formik.values.write.id  : ''}
                  onChange={(e, value) => {
                      formik.setFieldValue('write', {
                        id: e.target.value,
                        name: ''
                      })
                      formik.setFieldValue("write_id", e.target.value)
                    }
                  }
                  error={formik.touched.write && Boolean(formik.errors.write)}
                >
                  <MenuItem value="">
                    <span>Add Write Proficiency</span>
                  </MenuItem>
                  {taxonomyProficiency.map((proficiency) => (
                      <MenuItem key={proficiency.id} value={proficiency.id}>{proficiency.name}</MenuItem>
                    ) 
                  )}
                </Select>
            </div>

            <div className="form-group offset-lg-2 col-lg-8 col-md-12 mb-4">
                <Select
                  fullWidth
                  name="speak"
                  displayEmpty={true}
                  value={formik.values.speak ? formik.values.speak.id  : ''}
                  onChange={(e, value) => {
                      formik.setFieldValue('speak', {
                        id: e.target.value,
                        name: ''
                      })
                      formik.setFieldValue("speak_id", e.target.value)
                    }
                  }
                  error={formik.touched.speak && Boolean(formik.errors.speak)}
                >
                  <MenuItem value="">
                    <span>Add Speak Proficiency</span>
                  </MenuItem>
                  {taxonomyProficiency.map((proficiency) => (
                      <MenuItem key={proficiency.id} value={proficiency.id}>{proficiency.name}</MenuItem>
                    ) 
                  )}
                </Select>
              </div>
            </div>
            <div className="row">
              <div className="form-group offset-lg-2 col-lg-8 col-md-12 mb-4 d-flex justify-content-end">
                <button onClick={handleClose} className="theme-btn btn-style-eight narrow ">Cancel</button>
                <button type="submit" className="theme-btn btn-style-one narrow ms-2">Save</button>
              </div>
            </div>

          </DialogContent>

        </form>
      </Dialog>
    </div>
  )
};

export default LanguageForm;
