import React, { FC, useEffect, useState } from 'react';
import styles from './user-dropdown.module.scss';
import { userDropdownMenuItems } from './logic/menu';
import { isActiveLink } from '../../../../../utils/linkActiveChecker';
import { Link } from 'react-router-dom';
import 'bootstrap';

import * as bootstrap from 'bootstrap';
import { userLogout } from '../../../../../features/candidate/logic/candidateSlice';
import { useDispatch } from 'react-redux';
interface UserDropdownProps {}

const UserDropdown: FC<UserDropdownProps> = () => {
    const [isOpen, setIsOpen ] = useState(false);
    const dispatch = useDispatch();

    return (
    
      <div className="outer-box">
        <div className="dropdown dashboard-option">
            <a
                className="dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={() => setIsOpen(!isOpen)}
            >   
                <span className="icon icon-user"></span>
                <span className="name">My Account</span>
            </a>
    
            <ul className={(isOpen ? 'show' : '') + " dropdown-menu"} onClick={() => setIsOpen(!isOpen)}>
                {userDropdownMenuItems.map((item) => (
                    <li
                        key={item.id}
                    >
                        <Link to={item.routePath} onClick={() => {
                            const logout_id = 11;
                            if(item.id === logout_id) {
                                dispatch(userLogout());
                            }
                        }}>
                            <i
                                className={`la ${item.icon}`}
                            ></i>{" "}
                            {item.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
        {/* End dropdown */}
      </div>
  );
}

export default UserDropdown;
