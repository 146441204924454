import React, { FC, useEffect, useState } from 'react';
import styles from './job-details.module.scss';
import LoginPopup from '../../components/auth/login-popup/login-popup';
import Footer from '../../components/layout/footer/footer/footer';
import MobileMenu from '../../components/layout/header/components/mobile-menu/mobile-menu';
import Header from '../../components/layout/header/header/header';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch } from '../../app/store';
import { selectJobDetails, getJobDetails, getJobListRelated } from '../../features/job/details/logic/jobDetailsSlice';
import JobDetails from '../../features/job/details/details';
import CallToAction from '../../components/call-to-action/call-to-action';
import JobListBlock from '../../components/job-list-block/job-list-block';

interface JobProps {}

const Job: FC<JobProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  let { id } = useParams();
  const {
    isLoading, 
    jobDetails, 
    jobListRelated,
    jobCount,
    jobTodayCount
  } = useSelector(selectJobDetails);

  useEffect(() => {
    if (id) {
      dispatch(getJobDetails(parseInt(id ,10)));
      dispatch(getJobListRelated(parseInt(id ,10)));
    }
  }, [id])

  const [relatedSubHeader, setRelatedSubHeader] = useState('');
  useEffect(() => {
    let value = '';
    if (jobCount && jobTodayCount) {
      value = jobCount + " jobs live - " + jobTodayCount + " added today."
    }
    setRelatedSubHeader(value);

  }, [jobCount, jobTodayCount])
  return (
    <>
    
      {/* <!-- Header Span --> */}
      <span className="header-span"></span>
      
      <Header />
      {/* <!--End Main Header --> */}

      <MobileMenu />
      {/* End MobileMenu */}
      
      { jobDetails ? <JobDetails details={jobDetails}/> : ''}
      {/* End JobDetails */}

      <CallToAction/>
      {/* End CallToAction */}
  
      <JobListBlock title="Related Jobs" subtitle={relatedSubHeader} jobs={jobListRelated}/>
      {/* <!-- End Related Jobs --> */}
            
      <Footer/>
      {/* <!-- End Main Footer --> */}
    </>
  )
};

export default Job;
