import { useNavigate } from "react-router-dom"
import ApiAutocomplete from "../api-autocomplete/api-autocomplete";

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from "../../app/store";
import { applyFilters, selectSearch } from "../../features/job/search/logic/searchSlice";
import { useState } from "react";
import { FilterItemTypes, IFilterItem, TFilterItems } from "../../features/job/search/logic/models";
import { replaceFilters } from "../../app/helper";
import { TextField } from "@mui/material";

const SearchForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {
    filtersApplied,
  } = useSelector(selectSearch);

  const [ searchValue, setSearchValue ] = useState('');
    
  const setCurrentFilters = (filters: TFilterItems) => {
    dispatch(applyFilters(filters));
  }
  const handleSubmit = (event: any) => {
    setCurrentFilters([])
    if (searchValue) {
      setCurrentFilters([{
        category: "Free text",
        label: searchValue,
        type: "search",
        value: searchValue
      }]);
    }

    navigate('/search');
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        {/* <!-- Form Group --> */}
        <div className="form-group col-lg-8 col-md-12 col-sm-12">
          <label className="title">What</label>
          <div className="d-flex">
            <span className="icon flaticon-search-1"></span>
            <TextField
                fullWidth
                sx={{
                  ml: '10px'
                }}
                placeholder="Job title, keywords, or company"
                name="search"
                variant="standard"
                onChange={(e) => {
                    setSearchValue(e.target.value);
                  }
                }
                InputProps={{
                  disableUnderline: true,
                }}
              />
          </div>
        </div>
        <div className="form-group col-lg-3 col-md-12 col-sm-12 text-right">
          <button
            type="submit"
            className="theme-btn btn-style-one"
          >
            Find Jobs
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchForm;
