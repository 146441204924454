export const pageItems = [
    {
        name: "About",
        routePath: "/about",
    },
    {
        name: "Pricing",
        routePath: "/pricing",
    },
    {
        name: "FAQ's",
        routePath: "/faq",
    },
    {
        name: "Terms",
        routePath: "/terms",
    },
    {
        name: "Invoice",
        routePath: "/invoice",
    },
    {
        name: "Contact",
        routePath: "/contact",
    },
    {
        name: "404",
        routePath: "/404",
    },
];


export const mobileMenuDataSimple = [
    {
      id: 1,
      label: "Home",
      routePath: "/",
    },
    {
        id: 2,
        label: "Find Jobs",
        routePath: "/search",
    },
    {
        id: 3,
        label: "About Us",
        routePath: "/about-us",
    },
  ]
export const mobileMenuData = [
    {
      id: 1,
      label: "Home",
      items: [
          {
              name: "Home Page 01",
              routePath: "/",
          },
          {
              name: "Home Page 02",
              routePath: "/home-2",
          },
        ]
    },
    {
        id: 11,
        label: "Dashboard",
        items: [
            {
                name: "Employers Dashboard",
                routePath: "/employers-dashboard/dashboard",
            },
            {
                name: "Candidates Dashboard",
                routePath: "/candidates-dashboard/dashboard",
            },
        ],
    },
  ]