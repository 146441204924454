import React, { FC } from 'react';
import styles from './job-list-block.module.scss';
import { Link } from 'react-router-dom';
import { IJobListItem } from '../../features/job/details/logic/models';
import JobListItem from '../../features/job/components/job-list-item/job-list-item';

export interface JobListBlockProps {
  jobs: IJobListItem[];
  title: string;
  subtitle: string;
}

const JobListBlock: FC<JobListBlockProps> = (props) => {
  const { title, subtitle, jobs} = props;
  return (
    <>
      { jobs.length > 0 ? 
        <section className="job-section-two">
          <div className="auto-container">
              <div className="sec-title text-center">
                  <h2>{title}</h2>
                  <div className="text">
                    {subtitle}
                  </div>
              </div>

              <div className="row" data-aos="fade-up">
                  {jobs.slice(0, 6).map((item, index) => <JobListItem key={item.id} item={item} index={index}></JobListItem>)}
              </div>
              
              <div className="btn-box">
                  <Link
                  to="/search"
                  className="theme-btn btn-style-one bg-blue"
                  >
                  <span className="btn-title">Load More Listing</span>
                  </Link>
              </div>
          </div>
        </section>
      : ''}
    </>
  )
}

export default JobListBlock;
