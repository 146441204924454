import React, { FC, useEffect, useState } from 'react';
import styles from './user-social-form.module.scss';
import * as yup from 'yup';
import { TextField, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import CityAutocomplete from '../../../../components/city-autocomplete/city-autocomplete';
import { selectCountries, selectUserProfile, getCountries, getProfileDetails, getTaxonomies, patchCandidate } from '../../logic/candidateSlice';
import { ICountry, ICity } from '../../logic/models';

interface UserSocialFormProps {}

const validationSchema = yup.object({
  social_facebook: yup.string().nullable(),
  social_twitter: yup.string().nullable(),
  social_github: yup.string().nullable(),
  social_linkedin: yup.string().nullable(),
});
interface ICandidateValues {
    id? : number;
    social_facebook?: string | null,
    social_twitter?: string | null,
    social_github?: string | null,
    social_linkedin?: string | null,
  }

const UserSocialForm: FC<UserSocialFormProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectUserProfile);
  
  useEffect(() => {
    dispatch(getProfileDetails())
    dispatch(getTaxonomies())
  }, []);

  const initialValues:ICandidateValues = {
    social_facebook: '',
    social_twitter: '',
    social_github: '',
    social_linkedin: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values) {
        const sendValues = values;
        dispatch(patchCandidate(sendValues));
      }
    }
  })

  useEffect(() => {
    const userValues: ICandidateValues = {
      social_facebook: user?.candidate?.social_facebook,
      social_twitter: user?.candidate?.social_twitter,
      social_github: user?.candidate?.social_github,
      social_linkedin: user?.candidate?.social_linkedin,
    }
    formik.setValues(userValues);
  }, [user])

   return (
      <div className={styles.UserForm}>
       <form  onSubmit={formik.handleSubmit} className="default-form">
          <div className="row">

            {/* Facebook */}
            <div className="form-group col-lg-6 col-md-12">
                <label>Facebook</label>

                <TextField
                  fullWidth
                  name="social_facebook"
                  variant="standard"
                  value={formik.values.social_facebook ? formik.values.social_facebook : ''}
                  onChange={formik.handleChange}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
            </div>
            
             {/* Twitter */}
             <div className="form-group col-lg-6 col-md-12">
                <label>Twitter</label>

                <TextField
                  fullWidth
                  name="social_twitter"
                  variant="standard"
                  value={formik.values.social_twitter ? formik.values.social_twitter : ''}
                  onChange={formik.handleChange}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
            </div>


             {/* LinkedIn */}
             <div className="form-group col-lg-6 col-md-12">
                <label>LinkedIn</label>

                <TextField
                  fullWidth
                  name="social_linkedin"
                  variant="standard"
                  value={formik.values.social_linkedin ? formik.values.social_linkedin : ''}
                  onChange={formik.handleChange}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
            </div>

             {/* Facebook */}
             <div className="form-group col-lg-6 col-md-12">
                <label>GitHub</label>

                <TextField
                  fullWidth
                  name="social_github"
                  variant="standard"
                  value={formik.values.social_github ? formik.values.social_github : ''}
                  onChange={formik.handleChange}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
            </div>
            
          </div>

           <button className="theme-btn btn-style-one mb-3" type="submit" onClick={() => {formik.handleSubmit()}}>Save</button>
       </form>
      </div>
   );
}
export default UserSocialForm;
