import React, { FC } from 'react';
import styles from './login.module.scss';
import LoginForm from '../../features/auth/login-form/login-form';
import Semiscreen from '../../components/layout/semiscreen/semiscreen';
import VerifyUser from '../../features/auth/verify-user/verify-user';
interface LoginProps {}

const Login: FC<LoginProps> = () => {
  return (
    <Semiscreen>
      {/* <!-- Login Form --> */}
      <div className="login-form default-form">
        <h1>Login to JoBoard</h1>
        <LoginForm></LoginForm>
      </div>
      <VerifyUser></VerifyUser>
      {/* <!--End Login Form --> */}
    </Semiscreen>
  );
}

export default Login;
