import React, { FC } from 'react';
import styles from './change-password.module.scss';
import UserChangePasswordForm from '../../features/candidate/components/user-change-password-form/user-change-password-form';
import DashboardScreen from '../../components/layout/dashboard-screen/dashboard-screen';

interface ChangePasswordProps {}

const ChangePassword: FC<ChangePasswordProps> = () => (
  <DashboardScreen title="Change password">
    <div className="row">
      <div className="col-lg-12">
        <div className="ls-widget mb-0">
          <div className="widget-title">
            <h4>Change password</h4>
          </div>
          <div className="widget-content">
            <UserChangePasswordForm />
          </div>
        </div>
      </div>
    </div>
  </DashboardScreen>
);

export default ChangePassword;
