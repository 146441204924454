import React, { FC } from 'react';
import styles from './testimonial-section.module.scss';
import TestimonialCarousel from '../testimonial-carousel/testimonial-carousel.lazy';
import testimonialRight from '../../assets/images/resource/testimonial-right.png'
import testimonialLeft from '../../assets/images/resource/testimonial-left.png'
interface TestimonialSectionProps {}

const TestimonialSection: FC<TestimonialSectionProps> = () => {
  return (
    <section className="testimonial-section-two">
      <div className="container-fluid">
        <div className="testimonial-left">
          <img src={testimonialLeft} alt="testimonial" />
        </div>
        {/* End left img group */}

        <div className="testimonial-right">
          <img src={testimonialRight} alt="testimonial"/>
        </div>
        {/* End right img group */}

        <div className="sec-title text-center">
          <h2>Testimonials From Our Customers</h2>
          <div className="text">
            Lorem ipsum dolor sit amet elit, sed do eiusmod tempor
          </div>
        </div>
        {/* <!-- Sec Title --> */}

        <div className="carousel-outer" data-aos="fade-up">
          <div className="testimonial-carousel">
            <TestimonialCarousel />
          </div>
          {/* <!-- Testimonial Carousel --> */}
        </div>
      </div>
    </section>
  )
};

export default TestimonialSection;
