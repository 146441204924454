import React, { FC } from 'react';
import styles from './footer-content.module.scss';
import { Link } from 'react-router-dom';


export interface IMenuListItem {
  name: string,
  route: string
}
export interface IFooterContent {
  id: number,
  title: string,
  menuList: IMenuListItem[]
}
export interface FooterContentProps {
  content: IFooterContent[]
}

const FooterContent: FC<FooterContentProps> = (props) => {
  const { content } = props
    return (
    <>
      {content.slice(0, 3).map((item: IFooterContent) => (
        <div
          className="footer-column col-lg-3 col-md-6 col-sm-12"
          key={item.id}
        >
          <div className="footer-widget links-widget">
            <h4 className="widget-title">{item.title}</h4>
            <div className="widget-content">
              <ul className="list">
                {item?.menuList?.map((menu, i) => (
                  <li key={i}>
                    <Link to={menu.route}>{menu.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </>
)};

export default FooterContent;
