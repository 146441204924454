import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";
import { IJobListItem } from "../../details/logic/models";
import api, { apiAuthorization } from '../../../../app/api';
import { IFiltersApplied, TFilterItems } from "./models";
import { filterToQueryString } from "../../../../app/helper";

export interface ISearchState {
    data: IJobListItem[],
    isLoading: boolean,
    errorMessage: string,
    currentPage: number,
    totalPages: number,
    filtersAvailable?: any;
    filtersApplied: IFiltersApplied
}

const initialState: ISearchState = {
    data: [],
    filtersApplied: {
        filters: [],
        page: 1,
        recommended: false
    },
    isLoading: false,
    currentPage: 0,
    totalPages: 0,
    errorMessage: ''
}

export const selectSearch = (state: RootState) => state.search;

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        applyFilters(state, action) {
            state.filtersApplied.filters = action.payload;
            state.filtersApplied.page = 1;
            state.currentPage = 0;
            state.totalPages = 0;
            state.data = [];
        },
        nextPage(state, action) {
            if (action.payload.nextPageNumber < state.totalPages) {
                state.currentPage = action.payload.nextPageNumber;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFiltersAvailable.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getFiltersAvailable.fulfilled, (state, action) => {
            state.isLoading = false;
            state.filtersAvailable = action.payload;
        })
        .addCase(getFiltersAvailable.rejected, (state, action) => {
            state.isLoading = false;
            if (action.error.message)
                state.errorMessage = action.error.message;
        })
        .addCase(getJobPostItems.fulfilled, (state, action) => {
            if (state.currentPage === 0) {
                state.data = action.payload.results;
            }
            else {
                state.data = [
                    ...state.data,
                    ...action.payload.results
                ]
            }

            state.totalPages = action.payload.total_pages;
            if (action.payload.filters && action.payload.filters.length > 0) {
                state.filtersApplied.filters = action.payload.filters;
            }
        });
    },
})

export const getFiltersAvailable = createAsyncThunk(
    'search/getFiltersAvailable',
    async( type, {rejectWithValue}) => {
        try {
            const response = await api.get('/job/filters/');
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
);

export const getJobPostItems = createAsyncThunk(
    'search/getJobPostItems',
    async (options: IFiltersApplied | string, { rejectWithValue}) => {
        try {
            let response = { data: {results: [], total_pages: 0, filters: []}}
            if(typeof options === 'string') {
                response = await api.get('/job/' +  options + '&parse_filters=true');
            }
            else {
                response = await api.get('/job/?' + filterToQueryString(options));
            }
            return response.data;
        }
        catch (error) {
            return rejectWithValue('Error fetch items');
        }
    }
);

export const { nextPage, applyFilters } = searchSlice.actions;
export default searchSlice.reducer;
