import React, { FC, useEffect, useState } from 'react';
import styles from './job-overview.module.scss';
import { IJobDetails } from '../../details/logic/models';
import { ITerm } from '../../../candidate/logic/models';
import TaxonomyTags from '../../../../components/taxonomy-tags/taxonomy-tags';
import { timeAgo } from '../../../../app/helper';

export interface JobOverviewProps {
  details: IJobDetails
}

const JobOverview: FC<JobOverviewProps> = (props) => {
  const {details} = props;
  const {
    updated_at,
    other_skills,
    skills,
    benefits,
    locations,
    industries,
  } = props.details;

  let [ datePostedString, setDatePostedString] = useState('');
  useEffect(() => {
    datePostedString = '';
    if (updated_at) {
      datePostedString = timeAgo(updated_at);
    }
    setDatePostedString(datePostedString);
  }, [updated_at])

  let [ industryString, setIndustryString] = useState('');
  useEffect(() => {
    industryString = '';
    if (industries) {
      const values = industries.map(industry => industry.name);
      industryString = values.join(', ');
    }
    setIndustryString(industryString);
  }, [industries]) 

  let [locationStrings, setLocationsStrings] = useState<string[]>([]);
  useEffect(() => {
    locationStrings = [];
    if (locations) {
      for(let i = 0; i < locations.length; i++) {
        let tmpLocation = [];
        const locationItem = locations[i];
        if (locationItem.city) {
          tmpLocation.push(locationItem.city.name)
        }
        if (locationItem.state) {
          tmpLocation.push(locationItem.state.name)
        }
        if (locationItem.country) {
          tmpLocation.push(locationItem.country.name)
        }
        if(tmpLocation.length) {
          locationStrings.push(tmpLocation.join(', '));
        }
      }
    }
    setLocationsStrings(locationStrings)
  }, [locations]);

  return (
    <div className="sidebar-widget">
      {/* <!-- Job Overview --> */}
      <h4 className="widget-title">Job Overview</h4>
      <div className="widget-content">
        <ul className="job-overview">
          { datePostedString ? 
            <li>
              <i className="icon icon-calendar"></i>
              <h5>Date Updated:</h5>
              <span>{ datePostedString }</span>
            </li>
          : ''} 

          { industryString ? <li>
            <i className="icon icon-briefcase"></i>
            <h5>Industries:</h5>
            <span>{industryString}</span>
          </li> : '' }

          { locationStrings.length ? <li>
            <i className="icon icon-location"></i>
            <h5>Location:</h5>
            { locationStrings.map((item: string, i: number) => <div key={i}>{item}</div> ) }
          </li> : '' }
        </ul>
      </div>


      <TaxonomyTags title="Benefits" terms={benefits}/>
      {/* <!-- Job Benefits --> */}
      
      <TaxonomyTags title="Job Skills" terms={skills}/>
      <TaxonomyTags title="Other Skills" terms={other_skills}/>
      {/* <!-- Job Skills --> */}
    </div>
  );
}

export default JobOverview;
