import React, { FC, useEffect, useState } from 'react';
import styles from './education-form.module.scss';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Checkbox, FormControlLabel, FormControl, InputLabel, MenuItem, Select, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { patchEducation, postEducation, selectTaxonomyDegrees } from '../../logic/candidateSlice';
import { ICandidateEducation, ISkill, ITaxonomyItem } from '../../logic/models';
import { AppDispatch } from '../../../../app/store';
import SkillAutocomplete from '../../../../components/skill-autocomplete/skill-autocomplete';

export interface EducationFormProps {
  isEducationFormVisible?: boolean,
  setIsEducationFormVisible?: Function,
  setEducationFormValues?: Function,
  editEducation?: ICandidateEducation
}

const validationSchema = yup.object().shape({
  organization: yup
    .string()
    .required(),
  current_education: yup.boolean(),
  study_field: yup
    .string()
    .required(),
  degree_id: yup
    .number()
    .required(),
  skill_id: yup
    .array()
    .required(),
  from_date_month: yup
    .number(),
  from_date_year: yup
    .number()
    .required(),
  to_date_month: yup
    .number(),
  to_date_year: yup
    .number()
    .when('current_education', {
      is: true,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required(),
    })
    .min(yup.ref("from_date_year"))
});

const EducationForm: FC<EducationFormProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const taxonomyDegrees = useSelector(selectTaxonomyDegrees);

  const months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ]
  const now = new Date().getUTCFullYear();
  const YEARS_BEHIND = 100;

  // from joboard/taxonomy/fixtures/terms.json values
  const DEGREE_OTHER = 72;
  // Generate available years to select the education
  const years = Array(now - (now - YEARS_BEHIND)).fill('').map((v, idx) => now - idx);

  useEffect(() => {
    if (props.editEducation) {
      let values: ICandidateEducation = {
        ...props.editEducation
      };
      if (values.degree && !values.degree_id) {
        values.degree_id = values.degree.id;
      }

      if (values.skills && !values.skill_id) {
        values.skill_id = values.skills.map((skill) => skill.id);
      }
      formik.resetForm({});
      formik.setValues(values);
    }
    else {
      formik.resetForm({});
    }
  }, [props.editEducation])

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let isOpen = false;
    if (props.isEducationFormVisible != null)  {
      isOpen = props.isEducationFormVisible;
    }
    setOpen(isOpen);

  }, [props.isEducationFormVisible])
  const initialValues: ICandidateEducation = { };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const submitValues: ICandidateEducation = {
        organization: values.organization,
        from_date_month: values.from_date_month,
        from_date_year: values.from_date_year,
        to_date_month: values.to_date_month,
        to_date_year: values.to_date_year,
        current_education: values.current_education ? values.current_education : false,
        degree_id: values.degree_id,
        degree_other: values.degree_other,
        study_field: values.study_field ? values.study_field : '',
        skill_id: values.skill_id ? values.skill_id : [],
      };

      if (values?.id) {
        submitValues['id'] = values.id;
      }

      if (submitValues) {
        if (submitValues.id) {
          dispatch(patchEducation(submitValues));
        }
        else {
          dispatch(postEducation(submitValues));
        }
      }

      handleClose();
    }

  })

  const handleClose = () => {
    if (props.setIsEducationFormVisible) {
      props.setIsEducationFormVisible(false)
    }
    else {
      setOpen(false);
    }
    if (props.setEducationFormValues) {
      props.setEducationFormValues(initialValues);
    }
    formik.setValues(initialValues);
  };
  return(
    <div className={styles.educationForm}>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <form  onSubmit={formik.handleSubmit} className="container default-form">
            <DialogTitle>Education</DialogTitle>
            <DialogContent>
              <div className="row">
                  <div className="form-group col-lg-12 col-md-12">
                    <label>Organization</label>
                    <TextField
                      fullWidth
                      name="organization"
                      variant="standard"
                      value={formik.values.organization ? formik.values.organization : ''}
                      onChange={formik.handleChange}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </div>
                
                <div className="form-group col-lg-12 col-md-12">
                  <FormControlLabel control={<Checkbox
                    name="current_education"
                    checked={formik.values.current_education ? true : false}
                    onChange={(e, isChecked) => formik.setFieldValue('current_education', isChecked)}
                  />} label="Current Education" />
                </div>
                <div className="form-group col-md-6">
                  <label>From</label>
                  <div>
                    <div className="form-group d-inline-block w-50p">
                      <Select
                        fullWidth
                        name="from_date_month"
                        displayEmpty={true}
                        value={formik.values.from_date_month !=null ? formik.values.from_date_month : ""}
                        onChange={(e, value) => {
                            formik.setFieldValue("from_date_month", e.target.value)
                          }
                        }
                        error={formik.touched.from_date_month && Boolean(formik.errors.from_date_month)}
                      >
                        <MenuItem value="">
                          <em>Month</em>
                        </MenuItem>
                        {months.map((monthName, idx) => (
                            <MenuItem key={idx} value={idx}>{monthName}</MenuItem>
                          )
                        )}
                      </Select>
                    </div>
                    <div className="form-group d-inline-block w-50p">
                      <Select
                          fullWidth
                          displayEmpty={true}
                          name="from_date_year"
                          value={formik.values.from_date_year ? formik.values.from_date_year : ""}
                          onChange={(e, value) => {
                              formik.setFieldValue("from_date_year", e.target.value)
                            }
                          }
                          error={formik.touched.from_date_year && Boolean(formik.errors.from_date_year)}
                        >
                          <MenuItem value="">
                            <em>Year</em>
                          </MenuItem>
                          {years.map((value) => (
                              <MenuItem key={value} value={value}>{value}</MenuItem>
                            )
                          )}
                        </Select>
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label>To</label>
                  <div>
                    <div className="form-group d-inline-block w-50p">
                      <Select
                        fullWidth
                        displayEmpty={true}
                        name="to_date_month"
                        value={formik.values.to_date_month !=null ? formik.values.to_date_month : ""}
                        onChange={(e, value) => {
                            formik.setFieldValue("to_date_month", e.target.value)
                          }
                        }
                        error={formik.touched.to_date_month && Boolean(formik.errors.to_date_month)}
                      >
                        <MenuItem value="">
                          <em>Month</em>
                        </MenuItem>
                        {months.map((monthName, idx) => (
                            <MenuItem key={idx} value={idx}>{monthName}</MenuItem>
                          )
                        )}
                      </Select>
                      </div>
                    <div className="form-group d-inline-block w-50p">
                      <Select
                        fullWidth
                        displayEmpty={true}
                        name="to_date_year"
                        value={formik.values.to_date_year ? formik.values.to_date_year : ""}
                        onChange={(e, value) => {
                            formik.setFieldValue("to_date_year", e.target.value)
                          }
                        }
                        error={formik.touched.to_date_year && Boolean(formik.errors.to_date_year)}
                      >
                        <MenuItem value="">
                          <em>Year</em>
                        </MenuItem>
                        {years.map((value) => (
                            <MenuItem key={value} value={value}>{value}</MenuItem>
                          )
                        )}
                      </Select>
                    </div>
                  </div>
                </div>
                
                <div className="form-group col-lg-12 col-md-12">
                  <label>Study Field</label>
                  <TextField
                    fullWidth
                    name="study_field"
                    variant="standard"
                    value={formik.values.study_field ? formik.values.study_field : ''}
                    onChange={formik.handleChange}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </div>
                <div className="form-group col-lg-12 col-md-12">
                  <label>Degree</label>
                  <Select
                    fullWidth
                    name="degree"
                    value={formik.values.degree ? formik.values.degree?.id  : ''}
                    onChange={(e, value) => {
                        formik.setFieldValue("degree", {
                          id: e.target.value,
                          name: '' // not used
                        })
                        if (e.target.value !== DEGREE_OTHER) {
                          formik.setFieldValue('degree_other', null);
                        }
                        formik.setFieldValue("degree_id", e.target.value)
                      }
                    }
                    error={formik.touched.degree && Boolean(formik.errors.degree)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {taxonomyDegrees.map((term: ITaxonomyItem) => (
                        <MenuItem key={term.id} value={term.id}>{term.name}</MenuItem>
                      )
                    )}
                  </Select>
                </div>

                { formik.values.degree_id == DEGREE_OTHER ?
                  <div className="form-group col-lg-12 col-md-12 mb-5">
                    <label>Other Degree</label>
                    <TextField
                      fullWidth
                      name="degree_other"
                      variant="standard"
                      value={formik.values.degree_other ? formik.values.degree_other : ''}
                      onChange={formik.handleChange}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </div>
                : '' }

                <div className=" col-lg-12 col-md-12">
                  <label>Skills</label>
                  <SkillAutocomplete
                    value={formik.values.skills ? formik.values.skills : []}
                    multiple={true}
                    disableCloseOnSelect={true}
                    onChange={(values: ISkill[]) => {
                      formik.setFieldValue("skill_id", values?.map((value)=>value.id))
                      formik.setFieldValue("skills", values)
                    }}
                  ></SkillAutocomplete>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-lg-12 col-md-12 mt-2 mb-4 d-flex justify-content-end">
                  <button onClick={handleClose} className="theme-btn btn-style-eight narrow ">Cancel</button>
                  <button onClick={() => {formik.handleSubmit()}} type="submit" className="theme-btn btn-style-one narrow ms-2">Save</button>
                </div>
              </div>
            </DialogContent>
        </form>
      </Dialog>
    </div>
  )
};

export default EducationForm;
