import React, { lazy, Suspense } from 'react';
import { BlockCategoriesProps } from './block-categories';

const LazyBlockCategories = lazy(() => import('./block-categories'));

const BlockCategories = (props: BlockCategoriesProps & JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyBlockCategories {...props} />
  </Suspense>
);

export default BlockCategories;
