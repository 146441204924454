import React, { FC } from 'react';
import styles from './dashboard-screen.module.scss';
import Header from '../header/header/header';
import MobileMenu from '../header/components/mobile-menu/mobile-menu';
import DashboardSidebar from '../../dashboard-sidebar/dashboard-sidebar';
import DashboardMenuToggler from '../../dashboard-menu-toggler/dashboard-menu-toggler';
import BreadCrumb from '../../bread-crumb/bread-crumb';

export interface DashboardScreenProps {
  children?: string | JSX.Element | JSX.Element[],
  childrenSkipWapper?: boolean,
  title? : string
}

const DashboardScreen: FC<DashboardScreenProps> = (props) => 
{
  const childrenSkipWappeer = props.childrenSkipWapper ? props.childrenSkipWapper : false;
  return (
    <div className="page-wrapper dashboard">
      <span className="header-span"></span>
      {/* <!-- Header Span for hight --> */}

      <Header />
      {/* End Header */}

      <MobileMenu />
      {/* End MobileMenu */}

      <DashboardSidebar />
      {/* <!-- End Candidates Sidebar Menu --> */}

      {/* <!-- Dashboard --> */}
      <section className="user-dashboard">
        <div className="dashboard-outer">
          { props.title ? <BreadCrumb title={props.title} /> : '' }
          {/* breadCrumb */}

          <DashboardMenuToggler/>
          {/* Collapsible sidebar button */}

          { childrenSkipWappeer ? 
            props.children
          :
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  {props.children}
                </div>
              </div>
            </div>
          }
          {/* End .row */}
        </div>
        {/* End dashboard-outer */}
      </section>
      {/* <!-- End Dashboard --> */}

      {/* <CopyrightFooter /> */}
      {/* <!-- End Copyright --> */}
    </div>
    // End page-wrapper
  );
}

export default DashboardScreen;
