import React, { lazy, Suspense } from 'react';

const LazyUserCvUploaderForm = lazy(() => import('./user-cv-uploader-form'));

const UserCvUploaderForm = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyUserCvUploaderForm {...props} />
  </Suspense>
);

export default UserCvUploaderForm;
