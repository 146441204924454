import {
  ProSidebarProvider,
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import { Link, useLocation } from 'react-router-dom';
import SidebarFooter from "./SidebarFooter";
import SidebarHeader from "./SidebarHeader";
import {
  isActiveLink,
  isActiveParentChaild,
} from "../../../../../utils/linkActiveChecker";
import { mobileMenuDataSimple } from '../../../../../utils/mainMenuData';

const Index = () => {
  const location = useLocation()

  return (
    <div
      className="offcanvas offcanvas-start mobile_menu-contnet"
      tabIndex="-1"
      id="offcanvasMenu"
      data-bs-scroll="true"
    >
      <SidebarHeader />
      {/* End pro-header */}

      <ProSidebarProvider>
        <Sidebar>
          <Menu>
            {mobileMenuDataSimple.map((menuItem, i) => (
                <MenuItem
                  className={
                    isActiveLink(menuItem.routePath, location.location)
                      ? "menu-active-link"
                      : ""
                  }
                  key={i}
                  routerLink={<Link to={menuItem.routePath} />}
                >
                  {menuItem.label}
                </MenuItem>
            ))}
          </Menu>
        </Sidebar>
      </ProSidebarProvider>

      <SidebarFooter />
    </div>
  );
};

export default Index;
