import { useNavigate } from 'react-router-dom';

import api from "../../../../app/api";


export const googleSocialSuccess = (code: string) => {
    googleAccessToken(code);
}

const googleAccessToken = (authorizationCode: string) => {
  api.get(`users/google/access_token`, {
    params:{
      code: authorizationCode
    } 
  }).then((response: any) => {
    if (response.data.access_token) {
      socialLogin('google-oauth2', response.data.access_token);
    }
  })
}

export const linkedinAccessToken = (authorizationCode: string) => {
  api.get(`users/linkedin/access_token`, {
    params:{
      code: authorizationCode
    } 
  }).then((response: any) => {
    if (response.data.access_token) {
      socialLogin('linkedin-oauth2', response.data.access_token);
    }
  })
}

export const socialLogin = (backend: string, accessToken: string) => {
  api.post(`auth/convert-token/`, {
      token: accessToken,
      backend: backend,
      grant_type: 'convert_token',
      client_id: '6NZTYZGz07adB6GRmSnCwm3MxbLIG3TQqVQcdubg',
      client_secret: 'jL4PCR9N9vsAblSj5hskmdYiSuY2sHw25wVZDup8Q31chkZIpN0QuR211bQVXOWSXbcQwyvESyiPTHP0CmW9hxq8A5fASTiP4WldB6GnArgTvkzK9RsjfY5LFym0JeBZ',
  })
    .then((response: any) => {
      window.location.href = '/';
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
    })
}
