import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store";

export interface IGeneralState {
    isLoading: boolean,
    requestCounter: number
}

export const initialState: IGeneralState = {
    isLoading: false,
    requestCounter: 0
}

export const selectGeneralIsLoading = (state: RootState) => state.general.isLoading;

const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        increaseRequestCounter: (state, action) => {
            state.requestCounter++;
            state.isLoading = true;
        },
        decreaseRequestCounter: (state, action) => {
            state.requestCounter--;
            
            if (state.requestCounter < 0) {
                state.requestCounter = 0;
            }
            if (state.requestCounter === 0) {
                state.isLoading = false;
            }
        }
    }
});


export const {increaseRequestCounter, decreaseRequestCounter} = generalSlice.actions;
export default generalSlice.reducer;