import React, { FC, useEffect } from 'react';
import styles from './user-change-password-form.module.scss';
import * as yup from 'yup';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import { changePassword, getProfileDetails, selectUserProfile } from '../../logic/candidateSlice';
import { TextField } from '@mui/material';

interface UserChangePasswordFormProps {}
const validationSchema = yup.object({
  current_password: yup.string(),
  new_password: yup.string().min(8, "Must Contain 8 Characters").required()
  .matches(
    /^(?=.*[a-z])/,
    " Must Contain One Lowercase Character"
  )
  .matches(
    /^(?=.*[A-Z])/,
    "  Must Contain One Uppercase Character"
  )
  .matches(
    /^(?=.*[0-9])/,
    "  Must Contain One Number Character"
  ),
  new_password_confirm: yup
    .string()
    .oneOf([yup.ref('new_password')], 'Passwords must match')
});
interface ICandidateValues {
  current_password?: string | null,
  new_password?: string | null,
  new_password_confirm?: string | null,
}

const UserChangePasswordForm: FC<UserChangePasswordFormProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectUserProfile);
  
  useEffect(() => {
    dispatch(getProfileDetails())
  }, []);
  const initialValues:ICandidateValues = {
    current_password: '',
    new_password: '',
    new_password_confirm: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values) {
        const sendValues = {
          old_password: values.current_password,
          password: values.new_password,
          password_confirm: values.new_password_confirm,
        }
        dispatch(changePassword(sendValues));
      }
    }
  })
  return (
    <div className={styles.UserForm}>
      <form  onSubmit={formik.handleSubmit} className="default-form">
          <div className="row">

            <div className="form-group col-lg-12 col-md-12">
              <label>Current Password</label>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                name="current_password"
                value={formik.values.current_password}
                className={styles.TextField}
                onChange={formik.handleChange}
                error={formik.touched.current_password && Boolean(formik.errors.current_password)}
                helperText={(formik.touched.current_password && Boolean(formik.errors.current_password)) ? formik.errors.current_password : null }
              />
            </div>

            <div className="form-group col-lg-6 col-md-12">
              <label>New Password</label>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                name="new_password"
                value={formik.values.new_password}
                className={styles.TextField}
                onChange={formik.handleChange}
                error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                helperText={(formik.touched.new_password && Boolean(formik.errors.new_password)) ? formik.errors.new_password : null }
              />
            </div>
            <div className="form-group col-lg-6 col-md-12">
              <label>Confirm New Password</label>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                name="new_password_confirm"
                value={formik.values.new_password_confirm}
                className={styles.TextField}
                onChange={formik.handleChange}
                error={formik.touched.new_password_confirm && Boolean(formik.errors.new_password_confirm)}
                helperText={(formik.touched.new_password_confirm && Boolean(formik.errors.new_password_confirm)) ? formik.errors.new_password_confirm : null }
              />

            </div>
          </div>

          <button className="theme-btn btn-style-one mb-3" type="submit" onClick={() => {formik.handleSubmit()}}>Save</button>
      </form>
    </div>
  );
};

export default UserChangePasswordForm;
