import React, { FC, useEffect, useState } from 'react';
import styles from './resume.module.scss';
import DashboardScreen from '../../../components/layout/dashboard-screen/dashboard-screen';
import ResumeForm from '../../../features/candidate/resume-form/resume-form';
import SkillsTableForm from '../../../features/candidate/components/skills-table-form/skills-table-form';
import { getProfileDetails, getTaxonomies, selectCandidateProfile } from '../../../features/candidate/logic/candidateSlice';
import UserCvUploaderForm from '../../../features/candidate/components/user-cv-uploader-form/user-cv-uploader-form.lazy';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../app/store';

interface ResumeProps { }

const Resume: FC<ResumeProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [showCVUploader, setShowCVUploader] = useState(true);
  const { isLoading, userProfile} = useSelector(selectCandidateProfile);

  // load initial profile data
  useEffect(() => {
    dispatch(getProfileDetails())
    dispatch(getTaxonomies())
  }, [])

  useEffect(() => {
    let tmpShowCvUploader = false;
    if (
      !userProfile?.candidate?.skill.length
      && !userProfile?.candidate?.language.length
      && !userProfile?.candidate?.education.length
      && !userProfile?.candidate?.experience.length
    ) {
      tmpShowCvUploader = true;
    } 

    if (userProfile && userProfile.candidate) {
      setShowCVUploader(tmpShowCvUploader);
    }
  }, [userProfile]);
  return (
    <DashboardScreen title="My Resume" childrenSkipWapper={true}>
      
      {showCVUploader ?
        <div className="row">
          <div className="col-lg-12">
            {/* <!-- Ls widget --> */}
            <div className="ls-widget">
              <div className="tabs-box">
                <div className="widget-title">
                  <h4>CV Uploader</h4>
                </div>
                <div className="widget-content">
                  <UserCvUploaderForm></UserCvUploaderForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      : <>
        <div className="row">
          <div className="col-lg-12">
            {/* <!-- Ls widget --> */}
            <div className="ls-widget">
              <div className="tabs-box">
                <div className="widget-title">
                  <h4>My Resume</h4>
                </div>
                <div className="widget-content">
                  <ResumeForm />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {/* <!-- Ls widget --> */}
            <div className="ls-widget">
              <div className="tabs-box">
                <div className="widget-title">
                  <h4>Skills</h4>
                </div>
                <div className="widget-content">
                  <SkillsTableForm></SkillsTableForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      }
    </DashboardScreen>
  );
}

export default Resume;
