import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../../app/api";
import { RootState } from "../../../../app/store";
import { IJobDetails, IJobListItem } from "./models";

export interface IJobDetailsState {
    jobDetails: IJobDetails | null,
    jobListRelated: IJobListItem[],
    jobCount: number,
    jobTodayCount: number,
    isLoading: boolean,
}

const initialState: IJobDetailsState = {
    jobDetails: null,
    jobListRelated: [],
    jobCount: 0,
    jobTodayCount: 0,
    isLoading: true
}

export const selectJobDetails = (state: RootState) => state.jobDetails;

export const jobDetailsSlice = createSlice({
    name: 'jobDetails',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getJobDetails.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getJobDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.jobDetails = action.payload;
        })
        builder.addCase(getJobListRelated.fulfilled, (state, action) => {
            state.jobListRelated = action.payload.results;
            state.jobCount = action.payload.job_posts_count;
            state.jobTodayCount = action.payload.job_posts_today_count
        })
    },
});

export const getJobDetails = createAsyncThunk(
    'jobDetails/getJobDetails',
    async( jobId: number, { rejectWithValue }) => {
        try {
            const response = await api.get('job/' + jobId);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)


export const getJobListRelated = createAsyncThunk(
    'jobDetails/getJobListRelated',
    async( jobId: number, { rejectWithValue }) => {
        try {
            const response = await api.get('job/related/' + jobId);
            return response.data;
        }
        catch(error) {
            return rejectWithValue('Error');
        }
    }
)

export default jobDetailsSlice.reducer;