import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from '../features/candidate/logic/candidateSlice';
import {store} from '../app/store'; // Import your Redux store
import { decreaseRequestCounter, increaseRequestCounter } from "../features/general/logic/generalSlice";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    (config) => {
      store.dispatch(increaseRequestCounter());      

      const token = localStorage.getItem('access_token'); // Get the token from localStorage or wherever it's stored
  
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

api.interceptors.response.use(
  (response) => {
        store.dispatch(decreaseRequestCounter());      
        return response;
  },
  (error) => {
        store.dispatch(decreaseRequestCounter());      
        if (error.response && error.response.status === 401) {
            // Catch 401 errors and dispatch a Redux action (for example, logout user)
            store.dispatch(userLogout());      
        }
        return Promise.reject(error);
  }
);
export const apiAuthorizationFile = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // withCredentials: true,
  headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
  },
});
apiAuthorizationFile.interceptors.request.use(
  (config) => {
    store.dispatch(increaseRequestCounter());      

    const token = localStorage.getItem('access_token'); // Get the token from localStorage or wherever it's stored

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
apiAuthorizationFile.interceptors.response.use(
  (response) => {
        store.dispatch(decreaseRequestCounter());      
        return response;
  },
  (error) => {
        store.dispatch(decreaseRequestCounter());      
        if (error.response && error.response.status === 401) {
            // Catch 401 errors and dispatch a Redux action (for example, logout user)
            store.dispatch(userLogout());      
        }
        return Promise.reject(error);
  }
);
export const apiAuthorization = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    },
});
export default api;