import React, { FC } from 'react';
import styles from './modal-wrapper.module.scss';

export interface ModalWrapperProps {
  id: string
  children?: string | JSX.Element | JSX.Element[]

}

const ModalWrapper: FC<ModalWrapperProps> = (props) => (
  <div className="modal fade" id={props.id}>
      <div className="modal-dialog modal-lg modal-dialog-centered login-modal modal-dialog-scrollable">
        <div className="modal-content">

          <div className="modal-body">

            <button
              type="button"
              className="closed-modal"
              data-bs-dismiss="modal"
            ></button>
            {/* End close modal btn */}
            {/* <!-- modal --> */}
            <div id="login-modal">
                {props.children}
            </div>
            {/* <!-- End Module --> */}
          </div>
          </div>
          {/* En modal-body */}

        {/* End modal-content */}
      </div>
  </div>
);

export default ModalWrapper;
