import React, { FC, useEffect, useState } from 'react';
import styles from './verify-user.module.scss';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../../app/api';
import ModalWrapper from '../../../components/modal-wrapper/modal-wrapper';
import 'bootstrap';

import * as bootstrap from 'bootstrap';
interface VerifyUserProps {}

const VerifyUser: FC<VerifyUserProps> = () => {
  const navigate = useNavigate();
  
  const [myModal, setMyModal] = useState<any>();

  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(true);
  const [errorMessage, setErrorMesage] = useState('Something happened!');
  
  useEffect(() => {
    if (myModal) {
      myModal.show()
    }
  }, [myModal, success])

  useEffect(() => {
    if (searchParams.get('signature')) {
      setIsLoading(true);
    
      setMyModal(new bootstrap.Modal(('#verify-user'), {}))
      api.post('candidate/verify-registration/', {
          "user_id": searchParams.get('user_id'),
          "timestamp": searchParams.get('timestamp'),
          "signature": searchParams.get('signature')
        })
        .then((response: any) => {
          setIsLoading(false);
          setSuccess(true)
        })
        .catch((error) => {
          setIsLoading(false);
          setSuccess(false)
          setErrorMesage(error.response.data.detail);
        })
    }
  }, [searchParams])
  return (
    <div className={styles.VerifyUser}>
      { isLoading ? <div className='mask-loading'>Verifying...</div> : ''}
      <ModalWrapper id="verify-user">
        <>
          { success ? <div>
            <div className="text-center mt-4">
              <h3>Your email is confirmed!</h3>
              <p className="mt-3">
                Now you can log in.
              </p>
            </div>
          </div> : ''}
          {!success ? <div>
            <h2>Your email is confirmed!</h2>
            <br />
            <p>{errorMessage}</p>
          </div> : ''}
          <br />
          <br />
          <br />
          <Link 
            to={'/login'}
            className="theme-btn btn-style-one w-percent-100 py-4 mt-2 mb-5" 
            onClick={() => {
              if (myModal) {
                myModal.hide()
              }
            }}
          >
            Go To Login Page
          </Link>
        </>
      </ModalWrapper>
    </div>
  )
  };

export default VerifyUser;
