import React, { FC } from 'react';
import styles from './profile.module.scss';
import DashboardScreen from '../../../components/layout/dashboard-screen/dashboard-screen';
import UserForm from '../../../features/candidate/components/user-form/user-form';
import UserSocialForm from '../../../features/candidate/components/user-social-form/user-social-form';
import UserContactForm from '../../../features/candidate/components/user-contact-form/user-contact-form';
import UserIndustriesForm from '../../../features/candidate/components/user-industries-form/user-industries-form';

interface ProfileProps {}

const Profile: FC<ProfileProps> = () => {
 
  return (
    <DashboardScreen title="My Profile" childrenSkipWapper={true}>
      <div className="row">
        <div className="col-lg-12">
          <div className="ls-widget">
            <div className="widget-title">
              <h4>My Profile</h4>
            </div>
            <div className="widget-content">
              <UserForm></UserForm>
            </div>
          </div>
        </div>
      </div>
      {/* end my profile */}

      <div className="row">
        <div className="col-lg-12">
          <div className="ls-widget">
            <div className="widget-title">
              <h4>Industries</h4>
            </div>
            <div className="widget-content">
              <UserIndustriesForm></UserIndustriesForm>
            </div>
          </div>
        </div>
      </div>
      {/* end industries */}

      <div className="row">
        <div className="col-lg-12">
          <div className="ls-widget">
            <div className="widget-title">
              <h4>Social Network</h4>
            </div>
            <div className="widget-content">
              <UserSocialForm></UserSocialForm>
            </div>
          </div>
        </div>
      </div>
      {/* end social */}

      <div className="row">
        <div className="col-lg-12">
          <div className="ls-widget">
            <div className="widget-title">
              <h4>Contact Information</h4>
            </div>
            <div className="widget-content">
              <UserContactForm></UserContactForm>
            </div>
          </div>
        </div>
      </div>
      {/* end contanct information */}
    </DashboardScreen>
  );
}

export default Profile;
