import React, { FC } from 'react';
import styles from './header-nav-content.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { isActiveParentChaild, isActiveLink } from '../../../../../utils/linkActiveChecker';
import { pageItems } from '../../../../../utils/mainMenuData';

interface HeaderNavContentProps {}

const HeaderNavContent: FC<HeaderNavContentProps> = () => {
  const location = useLocation()
  
  return (
    <div className={styles.HeaderNavContent}>
        <nav className="nav main-menu">
          <ul className="navigation" id="navbar">
            <li
              className={`${
                '/'=== location.pathname ? "current" : ""
              }`}
            >
              <Link to='/'>Home</Link>
            </li>
            <li
              className={`${
                '/search' === location.pathname ? "current" : ""
              }`}
            >
              <Link to='/search'>Find Jobs</Link>
            </li>
            <li
              className={`${
                '/about-us' === location.pathname ? "current" : ""
              }`}
            >
              <Link to='/about-us'>About Us</Link>
            </li>
          </ul>
        </nav>
    </div>
  );
}



export default HeaderNavContent;
