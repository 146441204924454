export const userDropdownMenuItems = [
    {
      id: 2,
      name: "My Profile",
      icon: "la-user-tie",
      routePath: "/user/profile",
      active: "",
    },
    {
      id: 3,
      name: "My Resume",
      icon: "la la-file-invoice",
      routePath: "/user/resume",
      active: "",
    },
    {
      id: 4,
      name: "Applied Jobs",
      icon: "la-briefcase",
      routePath: "/user/applied-jobs",
      active: "",
    },
    {
      id: 10,
      name: "Change Password",
      icon: "la-lock",
      routePath: "/user/change-password",
      active: "",
    },
    {
      id: 11,
      name: "Logout",
      icon: "la-sign-out",
      routePath: "/login",
      active: "",
    },
  ];