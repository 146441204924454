import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import jobDetailsReducer from '../features/job/details/logic/jobDetailsSlice';
import searchReducer from '../features/job/search/logic/searchSlice';
import candidateReducer from '../features/candidate/logic/candidateSlice';
import pageReducer from '../features/page/logic/pageSlice';
import authReducer from '../features/auth/logic/authSlice';
import homeReducer from '../pages/home/logic/homeSlice';
import toggleSlice from '../components/dashboard-sidebar/logic/toogleSlice';
import generalSlice from '../features/general/logic/generalSlice';
export const store = configureStore({
  reducer: {
    auth: authReducer,
    general: generalSlice,
    search: searchReducer,
    jobDetails: jobDetailsReducer,
    candidate: candidateReducer,
    toggle: toggleSlice,
    page: pageReducer,
    home: homeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
