import React, { FC } from 'react';
import styles from './applied-jobs.module.scss';
import DashboardScreen from '../../../components/layout/dashboard-screen/dashboard-screen';
import JobApplications from '../../../features/candidate/job-applications/job-applications';

interface AppliedJobsProps {}

const AppliedJobs: FC<AppliedJobsProps> = () => (
  <DashboardScreen title="Applied jobs!">
    <JobApplications />
  </DashboardScreen>
);

export default AppliedJobs;
