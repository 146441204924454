import React, { FC, useEffect } from 'react';
import styles from './user-description-form.module.scss';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import { useFormik } from 'formik';
import { getProfileDetails, patchCandidate, selectUserProfile } from '../../logic/candidateSlice';
import { TextField, TextareaAutosize } from '@mui/material';

interface UserDescriptionFormProps {}
const validationSchema = yup.object({
  description: yup.string().nullable(),
});
interface ICandidateValues {
  id? : number;
  description?: string | null,
}
const UserDescriptionForm: FC<UserDescriptionFormProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectUserProfile);
  
  useEffect(() => {
    dispatch(getProfileDetails())
  }, []);

  const initialValues:ICandidateValues = {
    description: '',
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values) {
        const sendValues = values;
        dispatch(patchCandidate(sendValues));
      }
    }
  })
  useEffect(() => {
    const userValues: ICandidateValues = {
      description: user?.candidate?.description,
    }
    formik.setValues(userValues);
  }, [user])

   return (
      <div className={styles.UserForm} style={{marginBottom: '50px'}}>
       <form  onSubmit={formik.handleSubmit} className="default-form">
          <div className="row">

            <div className="form-group col-lg-12">
                <label>Description</label>

                <TextareaAutosize
                  name="description"
                  value={formik.values.description ? formik.values.description : ''}
                  onChange={formik.handleChange}
                />
            </div>
            
          </div>

           <button className="theme-btn btn-style-one mb-3" type="submit" onClick={() => {formik.handleSubmit()}}>Save</button>
       </form>
      </div>
   );

};

export default UserDescriptionForm;
